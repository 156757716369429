<template>
  <div class="page-wrap">
    <h2>
      Coming soon
    </h2>
  </div>
</template>

<script>
import Backend from "@/classes/backend";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "MetricsPage",
  components: {},
  data() {
    return {
      limit: 1000,
      sortBy: "timestamp",
      sortByAscending: false,
      purchasesList: [],
      totalPurchases: 0,
      totalUSD: 0,
      buyersList: {},
      isPurchasesLoading: true,
      isUsersLoading: true,
      isBetsLoading: true,
      isGamesDataLoading: true,
      selectedDate: "",
      totalUsers: 0,
      fromTimestamp: 1672617600000,
      gameStats: null,
      numberOfDays: 0,
      usersList: [],
      newUsersTable: {},
      activeUsersTable: {},
      betsTable: {},
      purchasesTable: {},
      chartOptions: {
        responsive: true
      },
      chartName: "DAU",
      returningBuyersAmount: 40,
    }
  },
  async mounted() {
    // this.getPurchases();
    // this.getUsers();
    // this.getGameStates();
  },
  watch: {},
  computed: {
    getUniqueBuyers() {
      let result = 0;
      this.purchasesList.forEach((purchase) => {
        if (this.buyersList[purchase.userId]) {
          this.buyersList[purchase.userId].times += purchase.quantity || 1;
          this.buyersList[purchase.userId].amount += purchase.totalPriceUsd;
        } else {
          result++;
          this.buyersList[purchase.userId] = {
            times: purchase.quantity || 1,
            amount: purchase.totalPriceUsd
          }
        }
      })
      return result;
    },
    getNumberOfSales() {
      let result = 0;
      this.purchasesList.forEach((purchase) => {
        result += purchase.quantity || 1;
      })
      return result;
    },
    getSales() {
      let result = 0;
      this.purchasesList.forEach((purchase) => {
        result += purchase.totalPriceUsd;
      })
      return Math.round(result * 100) / 100;
    },
    getARPPU() {
      let result = 0;
      Object.values(this.buyersList).forEach((buyer) => {
        result += buyer.amount;
      })
      result = result / Object.keys(this.buyersList).length
      return Math.round(result * 100) / 100;
    },
    getARPU() {
      let result = 0;
      Object.values(this.buyersList).forEach((buyer) => {
        result += buyer.amount;
      })
      result = result / this.totalUsers;
      return Math.round(result * 100) / 100;
    },
    getPP() {
      return Math.round(this.getUniqueBuyers / this.totalUsers * 10000) / 100;
    },
    getNewUsers() {
      if (this.selectedDate === "") {
        let total = 0;
        Object.values(this.newUsersTable).forEach(amount => {
          total += amount.total;
        })
        return total / Object.values(this.newUsersTable).length;
      }
      return this.newUsersTable[this.selectedTimestamp] ? this.newUsersTable[this.selectedTimestamp].total : 0;
    },
    getARPAU() {
      let count = 0;
      let sum = 0;
      Object.entries(this.activeUsersTable).forEach((value) => {
        // console.log(value[0], value[1].dailyActive,this.purchasesTable[value[0]])

        if (this.purchasesTable[value[0]]) {
          sum += this.purchasesTable[value[0]].total / value[1].dailyActive
          count++;
        }
      })
      return sum / count
    },
    getAverageNumBets() {
      let count = 0;
      let sum = 0;
      Object.entries(this.betsTable).forEach((value) => {
        // console.log(value[0], value[1].dailyActive,this.purchasesTable[value[0]])

        sum += value[1].numBets
        count++;
      })
      return sum / count
    },
    selectedTimestamp() {
      return new Date().valueOf() - new Date().valueOf() % (1000 * 60 * 60 * 24) - 1000 * 60 * 60 * 24;
    },
    lastDayTimestamp() {
      return new Date().valueOf() - new Date().valueOf() % (1000 * 60 * 60 * 24) - 1000 * 60 * 60 * 24;
    },
    lastNewUsersTimestamp() {
      return new Date().valueOf() - new Date().valueOf() % (1000 * 60 * 60 * 24);
    },
  },
  methods: {
    humanTime(timestamp) {
      let date = new Date(timestamp);
      return date.toUTCString();
    },
    async getUser(userID) {
      this.userData = await Backend.getUser(userID)
      this.page = userID;
    },
    async getPurchases() {

      this.isPurchasesLoading = true;
      let pages = await Backend.getPurchases(0, 1, this.sortBy).then(res => {
        return Math.ceil(res.total / this.limit);
      });
      this.total = 0;
      for (let i = 1; i <= pages; i++) {
        let purchasesList = await Backend.getPurchases(this.limit, i, this.sortBy, this.sortByAscending);
        if (purchasesList.total > 0) {
          this.purchasesList = this.purchasesList.concat(purchasesList.purchases);
          this.totalPurchases += purchasesList.total;
          this.totalUSD += purchasesList.totalUsd;
        }
      }
      // console.log(this.purchasesList)
      this.purchasesList.forEach(purchase => {
        if (this.purchasesTable[(purchase.timestamp - (purchase.timestamp % (1000 * 60 * 60 * 24)))]) {
          this.purchasesTable[(purchase.timestamp - (purchase.timestamp % (1000 * 60 * 60 * 24)))].total += purchase.totalPriceUsd
          this.purchasesTable[(purchase.timestamp - (purchase.timestamp % (1000 * 60 * 60 * 24)))].quantity += purchase.quantity || 1
          this.purchasesTable[(purchase.timestamp - (purchase.timestamp % (1000 * 60 * 60 * 24)))].buyers.push(purchase.userId)
        } else {
          this.purchasesTable[(purchase.timestamp - (purchase.timestamp % (1000 * 60 * 60 * 24)))] = {
            total: purchase.totalPriceUsd,
            quantity: purchase.quantity || 1,
            buyers: [purchase.userId]
          }
        }
      })
      this.isPurchasesLoading = false;
    },
    async getUsers() {
      this.isUsersLoading = true;
      this.totalUsers = await Backend.getUsers(0).then(res => {
        return res.total;
      });
      let pages = Math.ceil(this.totalUsers / this.limit);
      let usersList = [];
      for (let i = 1; i <= pages; i++) {
        usersList = usersList.concat(await Backend.getUsers(this.limit, i, "created", this.sortByAscending).then(res => {
          return res.users
        }));
        this.pageChanged = false;
      }
      let newUsersTable = {};

      usersList.forEach(user => {
        let timestamp = user.created - user.created % (1000 * 60 * 60 * 24);
        if (newUsersTable[timestamp]) {
          newUsersTable[timestamp].total++;
        } else {
          newUsersTable[timestamp] = {total: 1};
        }
      })
      this.isUsersLoading = false;
      this.usersList = usersList;
      this.newUsersTable = newUsersTable;
    },
    async getBets() {
      this.isBetsLoading = true;
      this.totalUsers = await Backend.getUsers(0).then(res => {
        return res.total;
      });
      this.isBetsLoading = false;
    },
    async getGameStates() {
      this.isGamesDataLoading = true;
      this.numberOfDays = 1;
      let timestamp = new Date().valueOf();
      if (this.fromTimestamp) {
        let startT = this.fromTimestamp + 1000 * 60 * 60 * 24;
        let activeUsersTable = {};
        let betsTable = {};
        while (startT <= timestamp) {
          this.isGamesDataLoading = true;
          let dateStats = await Backend.getGameStats(startT, this.game);
          this.isGamesDataLoading = false;
          if (!this.gameStats) {
            this.gameStats = dateStats;
          } else if (dateStats.all) {
            this.numberOfDays++;

            Object.entries(dateStats).forEach((dateGameStat) => {
              let dateGameName = dateGameStat[0];
              dateGameStat = dateGameStat[1];
              this.gameStats[dateGameName].dailyActive += dateGameStat.dailyActive ?? 0;
              this.gameStats[dateGameName].weeklyActive += dateGameStat.weeklyActive ?? 0;
              this.gameStats[dateGameName].monthlyActive += dateGameStat.monthlyActive ?? 0;
            })
          }
          activeUsersTable[startT] = {
            dailyActive: dateStats.all.dailyActive,
            weeklyActive: dateStats.all.weeklyActive,
            monthlyActive: dateStats.all.monthlyActive,
          };
          // console.log(dateStats.all)
          betsTable[startT] = {
            numBets: dateStats.all.numBets,
            numBigWins: dateStats.all.numBigWins,
            totalBet: dateStats.all.totalBet,
            totalWon: dateStats.all.totalWon,
          };
          startT += 1000 * 60 * 60 * 24;
        }
        this.activeUsersTable = activeUsersTable;
        this.betsTable = betsTable;
      } else {
        this.isGamesDataLoading = true;
        this.gameStats = await Backend.getGameStats(this.timestamp, this.game).then(res => {
          if (res.error) {
            window.alert(JSON.stringify(res));
            return null;
          }
          return res;
        })
        this.isGamesDataLoading = false;
      }

      this.isGamesDataLoading = false;
    },

    newUsers(timestamp) {
      if (!timestamp && this.selectedDate === "") {
        let total = 0;
        Object.values(this.newUsersTable).forEach(amount => {
          total += amount.total;
        })
        return total / Object.values(this.newUsersTable).length;
      }
      if (timestamp && this.newUsersTable[timestamp]) {
        return this.newUsersTable[timestamp].total;
      }
      return this.newUsersTable[this.selectedTimestamp] ? this.newUsersTable[this.selectedTimestamp].total : 0;
    },
    getRepeatBuyers(times) {
      let buyers = [];
      this.purchasesList.forEach(purchase => {
        buyers.push(purchase.userId)
      })
      let buyersMap = new Map();
      let amount = 0;
      buyers.forEach(id => {
        buyersMap.set(id, buyersMap.get(id) + 1 || 1)
      })
      buyersMap.forEach(value => {

        if (value > times)
          amount++;
      })
      return amount;
    },
    chartData(chartName) {
      let labels = [];
      let datasets = [{
        backgroundColor: '#ff595a',
        label: chartName,
        data: []
      }];
      if (chartName === "DAU" && Object.entries(this.activeUsersTable).length > 0) {
        Object.entries(this.activeUsersTable).forEach((value) => {
          // console.log(value)
          labels.push(new Date(parseInt(value[0])).toLocaleDateString("fr"));
          datasets[0].data.push(value[1].dailyActive)
        })
      }
      if (chartName === "New users" && Object.entries(this.newUsersTable).length > 0) {
        Object.entries(this.newUsersTable).forEach((value) => {
          labels.push(new Date(parseInt(value[0])).toLocaleDateString("fr"));
          datasets[0].data.push(value[1].total)
        })
      }
      if (chartName === "repeat buyers" && Object.entries(this.purchasesList).length > 0) {
        for (let i = 1; i <= this.returningBuyersAmount; i++) {
          labels.push(i);
          datasets[0].data.push(this.getRepeatBuyers(i))
        }
      }
      if (chartName === "number of bets" && Object.entries(this.betsTable).length > 0) {
        Object.entries(this.betsTable).forEach((value) => {
          // console.log(value)
          labels.push(new Date(parseInt(value[0])).toLocaleDateString("fr"));
          datasets[0].data.push(value[1].numBets)
        })
      }
      return {
        labels: labels,
        datasets: datasets,
      }
    },
  }
}

</script>

<style scoped>

.page {
  position: relative;
  margin: 0 30px;
  display: flex;
  flex-wrap: wrap;

  align-content: flex-start;
  gap: 10px;
}

.info-card {
  position: relative;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  height: 200px;
  width: 200px;
  background: #005da7;
  color: white;
  filter: drop-shadow(2px 4px 1px gray);
}

.loading-img {
  width: 80%;
}

form {
  text-align: left;
}


.page-wrap {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.chart-wrap {
  --x: 250px;
  height: var(--x);
  width: calc(var(--x) * 2);
}

img {
  max-width: 50px;
}
</style>