import {createRouter, createWebHashHistory } from 'vue-router';
import HomePage from "../components/pages/HomePage";
import PrizesPage from "../components/pages/PrizesPage";
import settingsPage from "@/components/pages/SettingsPage";
import statsPage from "@/components/pages/StatsPage";
import usersPage from "@/components/pages/UsersPage";
import logsPage from "@/components/pages/LogsPage";
import ProfilePage from "@/components/pages/ProfilePage";
import sentNotification from "@/components/pages/sentNotification";
import metricsPage from "@/components/pages/MetricsPage";
import redeemCodes from "@/components/pages/redeemCodes";


const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            name: 'Home',
            component: HomePage,
            redirect: '/metrics'
        },
        {
            path: '/prizes',
            name: 'Prizes Page',
            component: PrizesPage,
        },
        {
            path: '/settings',
            name: 'settings Page',
            component: settingsPage,
        },
        {
            path: '/stats',
            name: 'Stats Page',
            component: statsPage,
        },
        {
            path: '/users',
            name: 'Users Page',
            component: usersPage,
        },
        {
            path: '/logs',
            name: 'Logs Page',
            component: logsPage,
        },
        {
            path: '/profile',
            name: 'Profile Page',
            component: ProfilePage,
        },
        {
            path: '/notifications',
            name: 'Notifications Page',
            component: sentNotification,
        },
        {
            path: '/redeemcodes',
            name: 'Redeem Codes Page',
            component: redeemCodes,
        },
        {
            path: '/metrics',
            name: 'Metrics Page',
            component: metricsPage,
        },
    ],
});

export default router;