import {store} from "@/store";
import config from "../../config.json";

let backendUri = 'https://api.spincitytycoon.com/api/';


export default new class BackendCommunication {

    async addPrizes(timestamp, raffleType, prizes) {

        const body = {
            timestamp,//: 1669852800000 (the timestamp in milliseconds this daily/weekly/monthly raffle will be drawn)
            raffleType,//: 'daily'/'weekly'/'monthly',
            prizes,//: [] (array of prizes)
        }

        return await this.request(backendUri + 'addprizes/', body);
    }

    async claimPrizes(prizes) {

        const body = {
            prizes,//: [] (array of prizes)
        }

        return await this.request(backendUri + 'claimprizes/', body);
    }

    async removePrizes(prizes) {

        const body = {
            prizes,//: [] (array of prizes)
        }

        return await this.request(backendUri + 'removeprizes/', body);
    }

    async getPrizes(raffleType, timestamp){
        let timestamps = this.getRaffleTimestamps();

        const body = {
            timestamp: timestamp !== "" ? timestamp :
                raffleType === "daily" ? timestamps.dailyTimestamp :
                    raffleType === "weekly" ? timestamps.weeklyTimestamp :
                        timestamps.monthlyTimestamp,
            raffleType: raffleType,
        }

        return await this.request(backendUri + 'getprizes/', body);
    }

    async getEntries(raffleType, timestamp, userId) {
        let timestamps = this.getRaffleTimestamps();

        const body = {
            timestamp: timestamp !== "" ? timestamp :
                raffleType === "daily" ? timestamps.dailyTimestamp :
                    raffleType === "weekly" ? timestamps.weeklyTimestamp :
                        timestamps.monthlyTimestamp,
            raffleType: raffleType,
            userId: userId,
        }

        return await this.request(backendUri + 'getentries/', body);
    }

    async changePassword(oldPassword, newPassword) {

        const body = {
            oldPassword: oldPassword,
            newPassword: newPassword,
        }

        return await this.request(backendUri + 'changepassword/', body);
    }

    async getState() {

        const body = {}

        return await this.request(backendUri + 'getstate/', body);
    }

    async setState(state) {

        const body = {
            state
        }

        return await this.request(backendUri + 'setstate/', body);
    }

    async getUsers(limit, page, sortBy, sortByAscending, createdFrom, createdTo) {

        const body = {
            limit: limit,
            page: page,
            sortBy: sortBy,
            sortByAscending: sortByAscending,
            createdFrom: createdFrom,
            createdTo: createdTo
        }

        return await this.request(backendUri + 'getusers/', body);
    }

    async getEmails() {

        const body = {}

        return await this.request(backendUri + 'getemails/', body);
    }

    async getEmailsWithUserids() {

        const body = {}

        return await this.request(backendUri + 'getemailswithuserids/', body);
    }

    async getUser(userId) {

        const body = {
            userId: userId
        }

        return await this.request(backendUri + 'getuser/', body);
    }

    async setPrizeCode(prizeID, code) {

        const body = {
            prize: prizeID,
            code: code
        }

        return await this.request(backendUri + 'setprizecode/', body);
    }

    async getBets(limit, page, sortBy, sortByAscending, timestampFrom, timestampTo, userId, game, betType, jackpotWon,
                  bigWin, chipsWon) {

        const body = {
            limit: limit,
            page: page,
            sortBy: sortBy,
            sortByAscending: sortByAscending,
            game: game,
            userId: userId,
            betType: betType,
            timestampFrom: timestampFrom,
            timestampTo: timestampTo,
            jackpotWon: jackpotWon,
            bigWin: bigWin,
            chipsWon: chipsWon
        }

        return await this.request(backendUri + 'getbets/', body);
    }

    async getPurchases(limit, page, sortBy, sortByAscending, timestampFrom, timestampTo, userId, chain, purchaseType) {

        const body = {
            limit: limit,
            page: page,
            sortBy: sortBy,
            sortByAscending: sortByAscending,
            chain: chain,
            userId: userId,
            timestampFrom: timestampFrom,
            timestampTo: timestampTo,
            purchaseType: purchaseType
        }

        return await this.request(backendUri + 'getpurchases/', body);
    }

    async getBigWins() {

        const body = {}

        return await this.request(backendUri + 'getbiggestwins/', body);
    }


    async getGameStats(timestamp, game) {

        const body = {
            game: game,
            timestamp: timestamp,
        }

        return await this.request(backendUri + 'getgamestats/', body);
    }

    async getNotifications(type = "prize", limit = 10, page = 1, userId = null, timestampFrom = 0, timestampTo = new Date().valueOf()) {

        const body = {
            limit: limit,
            page: page,
            userId: userId,
            type: type,
            timestampFrom: timestampFrom,
            timestampTo: timestampTo,
        }

        return await this.request(backendUri + 'getnotifications/', body);
    }

    async sendNotification(title, message, users, type, prizeType, prizeAmount, game, monthlyCardType) {

        const body = {
            title: title,
            message: message,
            users: users,
            type: type,
            prizeType: prizeType,
            prizeAmount: prizeAmount,
            game: game,
            monthlyCardType: monthlyCardType,
        }

        return await this.request(backendUri + 'sendnotification/', body);
    }

    async createRedeemCodes(type, numCodes = 1, numChips = null, game = null, notes = "", expiryTimestamp = 0, numUsesPerCode, monthlyCardType) {

        const body = {
            type: type,
            numCodes: numCodes,
            numChips: numChips,
            game: game,
            notes: notes,
            expiryTimestamp: expiryTimestamp,
            numUsesPerCode: numUsesPerCode,
            monthlyCardType: monthlyCardType,
        }

        return await this.request(backendUri + 'createredeemcodes/', body);
    }

    async getRedeemCodes(type = null, limit = 100, page = 1, enabled = null, expired = null) {

        const body = {
            type: type,
            limit: limit,
            page: page,
            enabled: enabled,
            expired: expired
        }

        return await this.request(backendUri + 'getredeemcodes/', body);
    }

    async toggleRedeemCodes(redeemId, enabled) {

        const body = {
            redeemId: redeemId,
            enabled: enabled,
        }

        return await this.request(backendUri + 'toggleredeemcodes/', body);
    }


    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async request(uri, body) {
        body.env = store.getters.getEnvironment;

        if (body.env === "test") {
            uri = uri.replace("api", "apitest")
        }
        body.username = store.getters.getUsername;
        body.password = store.getters.getPassword;

        let maxTries = 10;
        let tries = 0;

        // eslint-disable-next-line no-constant-condition
        while (true) {
            try {
                return await this.fetchRequest(uri, body);
            } catch(e) {
                if(tries < maxTries && e.message == "Failed to fetch") {
                    tries++;
                    await this.sleep(2000);
                } else {
                    return { error: e.message }
                }
            }
        }

    }

    async fetchRequest(uri, body) {

        const response = await fetch(uri, {
            method: 'post',
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(body)
        });

        return await response.json();
    }

    getRaffleTimestamps() {
        const weeklyRaffleStartDay = 1 // Monday
        let dailyTimestamp = new Date();
        dailyTimestamp.setUTCHours(0, 0, 0, 0);
        dailyTimestamp.setUTCHours(24, 0, 0, 0);
        dailyTimestamp = dailyTimestamp.getTime();

        let weeklyTimestamp = new Date(dailyTimestamp);
        let day = weeklyTimestamp.getUTCDay();
        if(day !== config.weeklyRaffleStartDay) {
            if(config.weeklyRaffleStartDay < day) {
                day -= 7;
            }
            weeklyTimestamp.setUTCDate(weeklyTimestamp.getUTCDate() + weeklyRaffleStartDay - day)
        }
        weeklyTimestamp = weeklyTimestamp.getTime();

        let monthlyTimestamp = new Date(dailyTimestamp);
        if (monthlyTimestamp.getUTCDate() !== 1) {
            monthlyTimestamp.setUTCDate(1);
            monthlyTimestamp.setUTCMonth(monthlyTimestamp.getUTCMonth() + 1);
        }
        monthlyTimestamp = monthlyTimestamp.getTime();

        return {dailyTimestamp, weeklyTimestamp, monthlyTimestamp};
    }

    async getAssets() {
        return await fetch("https://wax.api.atomicassets.io/atomicassets/v1/assets?owner=blockspingam&page=1&limit=100&order=desc&sort=asset_id",
            {
                method: 'get',
                headers: {
                    "Content-type": "application/json"
                },
            }).then(response => {
            return response.json()
        });
    }

    async getChipStats() {
        return await fetch(
            "https://jackbot.blockspingaming.com/api/getchipstats",
            {
                method: 'post',
                headers: {
                    "Content-type": "application/json"
                },
                body: JSON.stringify({
                    env: 'prod',
                    password: "mS5q548JyAJhYCPG",
                })
            }).then(async response => {
            let data = await response.json()
            return [...Object.entries(data)].sort()
        });
    }

}