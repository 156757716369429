<template>
  <div class="page flex-column">
    <h2>
      Coming soon
    </h2>
  </div>
</template>

<script>
import Backend from "@/classes/backend";

export default {
  name: "LogsPage",
  components: {
  },

  data() {
    return {
      total: 0,
      totalUsd: 0,
      limit: 10,
      useridInput: "",
      sortBy: "timestamp",
      betsList: [],
      purchasesList: [],
      sortByAscendingInput: "false",
      jackpotWonInput: "false",
      bigWinInput: "false",
      type: null,
      chain: null,
      chipsWon: 0,
      page: "bets",
      pageNumber: 1,
      createdAfterInput: 0,
      createdBeforeInput: 0,
      gameInput: "all",
      chainInput: "all",
      openUser: false,
      selectedUserID: "",
      isLoading: true,
    }
  },
  watch: {
    type() {
      this.getBets();
    },
    chain() {
      this.getPurchases();
    },
    sortBy() {
      this.getBets();
    },
    limit() {
      this.getBets();
    },
    createdAfterInput() {
      this.getBets();
    },
    createdBeforeInput() {
      this.getBets();
    },
    gameInput() {
      this.getBets();
    },
    jackpotWonInput() {
      this.getBets();
    },
    bigWinInput() {
      this.getBets();
    },
  },
  mounted() {
    // if (this.page === 'bets') {
    //   this.getBets();
    // }
    // if (this.page === 'purchases') {
    //   this.getPurchases()
    // }
  },
  computed: {
    userid() {
      if (this.useridInput === "")
        return null;
      return this.useridInput;
    },
    game() {
      if (this.gameInput === "all")
        return null;
      return this.gameInput;
    },
    createdAfter() {
      if (this.createdAfterInput && this.createdAfterInput !== 0)
        return new Date(this.createdAfterInput).valueOf()
      return 0
    },
    createdBefore() {
      if (this.createdBeforeInput && this.createdBeforeInput !== 0)
        return new Date(this.createdBeforeInput).valueOf()
      return new Date().valueOf()
    },
    totalPages() {
      return Math.ceil(this.total / this.limit);
    },
    sortByAscending() {
      return JSON.parse(this.sortByAscendingInput)
    },
    jackpotWon() {
      return JSON.parse(this.jackpotWonInput)
    },
    bigWin() {
      return JSON.parse(this.bigWinInput)
    },
  },
  methods: {
    async getBets() {
      this.isLoading = true;
      let betsList = await Backend.getBets(this.limit, this.pageNumber, this.sortBy, this.sortByAscending,
          this.createdAfter, this.createdBefore, this.userid, this.game, this.type, this.jackpotWon, this.bigWin,
          this.chipsWon);
      if (betsList.total > 0) {
        this.betsList = betsList.bets;
        this.total = betsList.total;
      }
      this.isLoading = false;
      return betsList.bets;
    },
    async getPurchases() {
      this.isLoading = true;
      let purchasesList = await Backend.getPurchases(this.limit, this.pageNumber, this.sortBy, this.sortByAscending,
          this.createdAfter, this.createdBefore, this.userid, this.chain, this.type);
      if (purchasesList.total > 0) {
        this.purchasesList = purchasesList.purchases;
        this.total = purchasesList.total;
        this.totalUsd = purchasesList.totalUsd;
      }
      this.isLoading = false;
      return purchasesList.purchases;
    },

    async getBiggestWins() {
      this.isLoading = true;
      this.biggestWinsList = await Backend.getBigWins();
      this.isLoading = false;
      return this.biggestWinsList;
    },

    updateTable() {
      switch (this.page) {
        case "bets": {
          this.getBets();
          break;
        }
        case "purchases": {
          this.getPurchases();
          break;
        }
        case "bigwins": {
          this.getBigWins();
          break;
        }
      }
    },
    humanTime(timestamp) {
      let date = new Date(timestamp);
      return date.toUTCString();
    },
  }
}
</script>

<style scoped>
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.page {
  height: 100%;
  width: 100%;
  position: relative;
  margin: 0 30px;
  overflow: auto;
}


div {
  text-align: left;
}

.userPage {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  overflow: auto;
  background: white;
  margin: -20px 0 0;
  padding: 0 40px;
}

.closeUserPage {
  position: fixed;
  top: 80px;
  left: 0;
}
</style>