<template>
  <div>
    <img src="../../assets/blockspin-logo.png" style="width: 1080px;" alt=""/>
    <div class="login-wrap"
    >
      <form class="flex-column">
        <div>
          <label for="username">username:</label>
          <input id="username" v-model="username"/>
        </div>
        <div>
          <label for="password">password:</label>
          <input id="password" v-model="password" type="password"/>
        </div>
        <div>
          <button @click="login()">login</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginPage",
  data() {
    return {
      username: "",
      password: ""
    }
  },
  methods: {
    login() {
      this.$store.dispatch("login", {username: this.username, password: this.password})
    }
  }
}
</script>

<style scoped>
.login-wrap {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translateX(50%);
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>