<template>
  <div>
    <h2>Profile:</h2>
    <form class="flex-column">
      <h2>Change password:</h2>
      <div>
        <label for="username">username:</label>
        <input id="username" v-model="username"/>
      </div>
      <div>
        <label for="oldpassword">Old password:</label>
        <input id="oldpassword" v-model="oldPassword" type="password"/>
      </div>
      <div>
        <label for="password">New password:</label>
        <input id="password" v-model="password" type="password"/>
      </div>
      <div>
        <button @click="confirm()">Confirm</button>
      </div>
    </form>
  </div>
</template>

<script>
import Backend from "@/classes/backend";

export default {
  name: "ProfilePage",
  data() {
    return {
      username: "",
      oldPassword: "",
      password: ""
    }
  },
  methods: {
    async confirm() {
      let res = await Backend.changePassword(this.oldPassword, this.password);
      window.alert(JSON.stringify(res))
      if (res.success) {
        this.$store.commit("changePassword", this.password);
      }
    }
  }
}
</script>

<style scoped>
.login-wrap {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translateX(50%);
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>