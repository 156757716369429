<template>
  <div class="flex-column wrap">
    <div class="flex-row header">
      <img src="../assets/blockspin-logo.png" alt="" style="height: 100%">
      <div class="flex-column">
        {{ $store.getters.getUsername }}
        <button @click="$router.push('/profile')">Change Password</button>
        <button @click="$store.dispatch('logout')">logout</button>
      </div>
      <div class="flex-column">
        <p>Environment</p>
        <select v-model="environment">
          <option>prod</option>
          <option>test</option>
        </select>
      </div>
    </div>
    <div class="flex-row body">
      <div class="flex-column menu">
        <router-link to="/stats" class="menu-button">
          Stats
        </router-link>
        <router-link to="/" class="menu-button">
          Access
        </router-link>
        <router-link to="/users" userid="all" class="menu-button">
          Users
        </router-link>
        <router-link to="/notifications" userid="all" class="menu-button">
          Notifications
        </router-link>
        <router-link to="/redeemcodes" userid="all" class="menu-button">
          Redeem Codes
        </router-link>
        <router-link to="/settings" class="menu-button">
          Settings
        </router-link>
        <router-link to="/prizes" class="menu-button">
          Prizes
        </router-link>
        <router-link to="/logs" class="menu-button">
          Logs
        </router-link>
        <router-link to="/metrics" class="menu-button">
          Metrics
        </router-link>
      </div>
      <router-view/>
    </div>
  </div>

</template>

<script>
export default {
  name: 'MenusWarp',
  data() {
    return {
      environment: this.$store.getters.getEnvironment,
    }
  },
  watch: {
    environment(newEnvironment) {
      console.log(newEnvironment)
      this.$store.dispatch('setEnvironment', newEnvironment);
      this.$router.go();
    }
  },
  computed: {},
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.header {
  height: 10%;
  width: 100%;
  top: 0;
  left: 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wrap{
  height: 87%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
}
.body{
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: relative;
}
.menu{
  height: 100%;
  width: 20%;
  top: 0;
  left: 0;
  position: relative;
  gap: 1px;

}
.menu-button{
  display: flex;
  align-items: center;
  justify-content: center;

  height: 7%;
  cursor: pointer;
  background: #005da7;
  border: unset;

  color: white;
  font-size: 14px;

  transition: ease-in-out 200ms;
  &:hover{
    transform: scale(0.95);
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.83));
    border-radius: 5px;
  }
}
</style>
