<template>
  <div class="loading-page">
    <img src="../../src/assets/loading.gif" alt="" style="width: 400px"/>
    <span>Loading</span>
  </div>
</template>

<script>
export default {
  name: "loading-component"
}
</script>

<style scoped>
.loading-page {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
}
</style>