<template>
  <div class="page flex-column">
    <h2>Settings:</h2>
    <div class="flex-row">
      <button @click="page='spincity'">
        Spincity
      </button>
    </div>
    <form class="flex-column">

      <div v-if="page==='spincity'">
        <p>Buildings:</p>

        <input value="Code" disabled="true"/>
        <input value="Name" disabled="true"/>
        <input value="Description" disabled="true"/>
        <input value="Buiding price USD" disabled="true"/>
        <input value="runningCost" disabled="true"/>
        <input value="buildTime" disabled="true"/>
        <input value="machinesAllowed" disabled="true"/>
        <input value="Upgrade Cost USD" disabled="true"/>
        <div v-for="(value, key, index) in buildings"
             :key="index"
        >
          <div>
            <input :value="key" disabled="true"/>
            <input v-model="value.name" placeholder="name" :disabled="!value.name"/>
            <input v-model="value.description" placeholder="description" :disabled="!value.description"/>
            <input v-model.number="value.buildingPriceUSD" placeholder="buildingPriceUSD" :disabled="!value.buildingPriceUSD"/>
            <input v-model="value.runningCost" placeholder="runningCost" :disabled="!value.runningCost"/>
            <input v-model="value.buildTime" placeholder="buildTime" :disabled="!value.buildTime"/>
            <input v-model="value.machinesAllowed" placeholder="machinesAllowed" :disabled="!value.machinesAllowed"/>
            <input v-model="value.upgradeCostUSD" placeholder="upgradeCostUSD" :disabled="!value.upgradeCostUSD"/>
          </div>
        </div>
        <br/>
        <p>Roles:</p>
        <br/>

        <input value="Code" disabled="true"/>
        <input value="Name" disabled="true"/>
        <input value="Description" disabled="true"/>
        <input value="current" disabled="true"/>
        <input value="Winning Bid" disabled="true"/>
        <input value="bids" disabled="true"/>
        <input value="next election" disabled="true"/>
        <div v-for="(value, key, index) in roles"
             :key="index"
        >
          <div>
            <input :value="key" disabled="true"/>
            <input v-model="value.name" placeholder="name" :disabled="!value.name"/>
            <input v-model="value.description" placeholder="description" :disabled="!value.description"/>
            <input v-if="value.current" v-model="value.current.username" placeholder="current" :disabled="true"/>
            <input v-else v-model="value.current" placeholder="current" :disabled="true"/>
            <input v-model.number="value.winningBid" placeholder="winningBid" :disabled="!value.winningBid"/>
            <input v-model="value.bids" placeholder="bids" :disabled="!value.bids"/>
            <input v-model="value.nextElectionTimestamp" placeholder="nextElectionTimestamp" :disabled="!value.nextElectionTimestamp"/>
          </div>
        </div>
        <br/>
        <div>
          <input :value="'Usd To Gems Ratio: 1$ = '+usdToGemsRatio+' Gems' " disabled="true"/>
          <input v-model.number="usdToGemsRatio" placeholder="usdToGemsRatio"/>
          <input disabled="true"/>
        </div>
        <div>
          <input value="Max bet" disabled="true"/>
          <input v-model.number="jackbotPremiumUserMaxBet" placeholder="jackbotPremiumUserMaxBet"/>
          <input v-model.number="jackbotPremiumServerMaxBet" placeholder="jackbotPremiumServerMaxBet"/>
          <input disabled="true"/>Chips
        </div>
        <div>
          <input value="Cooldown" disabled="true"/>
          <input v-model.number="jackbotPremiumUserCooldown" placeholder="jackbotPremiumUserCooldown"/>
          <input v-model.number="jackbotPremiumServerCooldown" placeholder="jackbotPremiumServerCooldown"/>
          <input v-model.number="jackbotCooldown" placeholder="jackbotCooldown"/>Seconds
        </div>
      </div>
    </form>
    <div>
      <br />
      <button @click="resetSettingseForm()">Reset settings</button>
      <button @click="applySettings()">Apply settings</button>
    </div>
  </div>
</template>

<script>
  import Backend from "@/classes/backend";

  export default {
    name: "SettingsPage",

    data() {
      return {
        selectedGameName: "",
        selectedGameUnlockPrice: 10000,
        selectedGameUnlockDiscount: 0,
        page: "spincity",
        chipsPrice: [0.99, 4.99, 9.99, 49, 99],
        chipsAmount: [1000, 5500, 12000, 70000, 200000],
        chipsDiscount: [0, 0, 0, 0, 0],
        startChipsDiscord: 1000,
        startChipsNonDiscord: 100,
        referralChipsBonus: 1000,
        dailyChipsDiscord: 1000,
        dailyChipsNonDiscord: 100,
        dailyEntriesReductionPercentage: 0,
        weeklyEntriesReductionPercentage: 0,
        monthlyEntriesReductionPercentage: 0,
        bronzeRaffleGemPrice: 10000,
        silverRaffleGemPrice: 100000,
        goldRaffleGemPrice: 400000,
        raffleClaimDays: 2,
        xpPerLevelIncreasePercentage: 10,
        dailyChipsPerLevelIncreasePercentage: 9,
        betsPossible: [10, 20, 50, 100, 200, 500, 1000],
        minBetJackpot: 200,
        jackpotPercentage: 1,
        jackpotThreshold: 0,
        jackpotAboveThresholdPercentage: 0,
        bigWinMultiplier: 10,
        leaderboardPrizeHourly: {slot: 1000, scratch: 1000, crash: 1000, instant: 1000},
        leaderboardPrizeDaily: {slot: 10000, scratch: 10000, crash: 10000, instant: 10000},
        leaderboardPrizeWeekly: {slot: 100000, scratch: 100000, crash: 100000, instant: 100000},
        leaderboardPrizeSplit: [30, 20, 15, 8, 7, 6, 5, 4, 3, 2],
        gamesUnlockedForAll: ['kingarthur', 'wildwest', 'nightoffear', 'cartel', 'tigerland'],
        gamesGemUnlockPrice: {
          cleopatra: 10000,
          spymaster: 10000,
          samurai: 10000,
          surfin: 10000,
          pirates: 10000,
          thebigfive: 10000,
          aladdin: 10000,
        },
        gamesGemUnlockDiscount: {
          cleopatra: 0,
          spymaster: 0,
          samurai: 0,
          surfin: 0,
          pirates: 0,
          thebigfive: 0,
          aladdin: 0,
        },
        levelUpChipsRewardMultiplier: 100,
        achievementRewards: {
          chad: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          master: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          trainwreck: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          slotsohollic: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          vip: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          trophy: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          newyear2023: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          streak100: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          bigwinner: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          chickendinner: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          excalibur: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          outlaw: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          kingpin: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          zhuhou: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          ghost: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          scarabs: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          topsecret: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          shogun: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          surfer: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          pirate: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          jungleking: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
          genie: {
            chips: 10000,
            gems: 0,
            bronzeTickets: 0,
            silverTickets: 0,
            goldTickets: 0
          },
        },
        monthlyCards: {
          bronze: {
            price: 4.99,
            discount: 0,
            dailyChipsBonusPercentage: 50,
            daysStreakInsurance: 1,
            maxBet: 2000,
            chipsDiscountPercentage: 25,
            unlockAllGames: false,
            entriesBonusPercentage: 0,
            turboMode: false,
            xPBonusPercentage: 0,
            gemsBonusPercentage: 0
          },
          silver: {
            price: 19.99,
            discount: 0,
            dailyChipsBonusPercentage: 100,
            daysStreakInsurance: 7,
            maxBet: 5000,
            chipsDiscountPercentage: 50,
            unlockAllGames: true,
            entriesBonusPercentage: 50,
            turboMode: true,
            xPBonusPercentage: 100,
            gemsBonusPercentage: 0
          },
          gold:
              {
                price: 49.99,
                discount: 0,
                dailyChipsBonusPercentage: 100,
                daysStreakInsurance: 30,
                maxBet: 10000,
                chipsDiscountPercentage: 75,
                unlockAllGames: true,
                entriesBonusPercentage: 100,
                turboMode: true,
                xPBonusPercentage: 400,
                gemsBonusPercentage: 100
              }
        },
        bronzeMonthlyCardsDiscount: 0,
        silverMonthlyCardsDiscount: 0,
        goldMonthlyCardsDiscount: 0,

        usdToGemsRate: 150000,
        boosts: {
          insurance1Day: {
            price: 1,
            discount: 0
          },
          insurance7Day: {
            price: 6,
            discount: 0
          },
          xpBoost: {
            price: 3,
            discount: 0
          },
          megaXpBoost: {
            price: 6,
            discount: 0
          },
          xpFreeze: {
            price: 10,
            discount: 0
          },
          xpRecovery: {
            price: 30,
            discount: 0
          },
        },
        guestMaxBets: 50,


        // state
        jackpot: 0,
        lastLeaderboardPaid: 1670371200000,
        lastRaffleDrawn: 1670371200000,
        // automatically set state
        waxUsdPrice: 100,
        maticUsdPrice: 100,
        bnbUsdPrice: 100,
        solUsdPrice: 100,
        betCooldownSeconds: 0,

        //jackbot
        jackbotPremiumUserPrice: 9.99,
        jackbotPremiumUserDiscountPercentage: 0,
        jackbotPremiumServerPrice: 9.99,
        jackbotPremiumServerDiscountPercentage: 0,
        jackbotPremiumUserMaxBet: 10000,
        jackbotPremiumServerMaxBet: 10000,
        jackbotCooldown: 5,
        jackbotPremiumUserCooldown: 3,
        jackbotPremiumServerCooldown: 3,

        //spincity
        buildings:{
          casino:{
            name:"Hotel Casino",
            description:"Your hotel, you put your slot machines here",
            priceGems: 0,
            priceUSD: 0,
            buildingPriceUSD: [1, 2, 3, 4, 5],
            runningCost: [10, 20, 30, 40, 50],
            buildTime: [0, 6*60*60, 12*60*60, 16*60*60, 24*60*60, 32*60*60],
            machinesAllowed: [1, 2, 3, 4, 5],
            upgradeCostUSD:[1,2,3,4]
          },
          bank:{
            name:"Bank",
            description:"Required for bank balance",
            buildingPriceGems: [150000],
            buildingPriceUSD: [1],
            runningCost: [10],
            buildTime: [6*60*60],
          },
          convention:{
            name:"Convention Center",
            description:"Required for level 5 hotel",
            buildingPriceGems: [150000],
            buildingPriceUSD: [5],
            runningCost: [50],
            buildTime: [5*6*60*60],
          },
          club:{
            name:"Night Club",
            description:"Required for level 3 hotel",
            buildingPriceGems: [150000],
            buildingPriceUSD: [3],
            runningCost: [30],
            buildTime: [3*6*60*60],
          },
          police:{
            name:"Police Station",
            description:"Required for level 5 hotel",
            buildingPriceGems: [150000],
            buildingPriceUSD: [5],
            runningCost: [50],
            buildTime: [5*6*60*60],
          },
          restaurant:{
            name:"Restaurant",
            description:"Required for level 2 hotel",
            buildingPriceGems: [150000],
            buildingPriceUSD: [2],
            runningCost: [20],
            buildTime: [2*6*60*60],
          },
          mall:{
            name:"Shopping Mall",
            description:"Required for level 4 hotel",
            buildingPriceGems: [150000],
            buildingPriceUSD: [4],
            runningCost: [40],
            buildTime: [4*6*60*60],
          },
          shop:{
            name:"Shop",
            description:"Required for level 2 hotel",
            buildingPriceGems: [150000],
            buildingPriceUSD: [2],
            runningCost: [20],
            buildTime: [2*6*60*60],
          },
          spa:{
            name:"SPA",
            description:"Required for level 4 hotel",
            buildingPriceGems: [150000],
            buildingPriceUSD: [4],
            runningCost: [40],
            buildTime: [4*6*60*60],
          },
          gym:{
            name:"GYM",
            description:"Required for level 3 hotel",
            buildingPriceGems: [150000],
            buildingPriceUSD: [3],
            runningCost: [30],
            buildTime: [3*6*60*60],
          },
          cinema:{
            name:"Cinema",
            description:"Required for level 4 hotel",
            buildingPriceGems: [150000],
            buildingPriceUSD: [4],
            runningCost: [40],
            buildTime: [4*6*60*60],
          },
        },
        roles:{
          mayor:{
            name:"Mayor",
            current: {
              username:"Bavaro",
              userID:"63a376780f288788481a45f2"
            },
            winningBid:0,
            description:"Takes 40% casino upgrade cost and takes 50% monthly landplot lease",
            bids:[],
            nextElectionTimestamp:0,
          },
          police:{
            name:"Police commissioner",
            current:null,
            winningBid:0,
            description:"Takes 40% from the building cost",
            bids:[],
            nextElectionTimestamp:0,
          },
          fire:{
            name:"Fire commissioner",
            current:null,
            winningBid:0,
            description:"Takes 40% from firebuilding cost",
            bids:[],
            nextElectionTimestamp:0,
          },
          gambling:{
            name:"Gambling commissioner",
            current:null,
            winningBid:0,
            description:"Takes 50% of the gambling licence",
            bids:[],
            nextElectionTimestamp:0,
          },
          banker:{
            name:"Banker",
            current:null,
            winningBid:0,
            description:"Takes 1% when transferring to Bank or withdraw",
            bids:[],
            nextElectionTimestamp:0,
          },
          insuer:{
            name:"Insurer",
            current:null,
            winningBid:0,
            description:"Takes 50% of the insurance",
            bids:[],
            nextElectionTimestamp:0,
          },
          constructor:{
            name:"Constructor",
            current:null,
            winningBid:0,
            description:"Takes 10% construction cost and 10% building repair cost",
            bids:[],
            nextElectionTimestamp:0,
          },
        },
        usdToGemsRatio:150_000,
      }
    },
    mounted() {
       this.resetSettingseForm();
    },
    computed: {
      contract(){
        return this.chain==='WAX'?'atomicassets':this.inputContract;
      },
      timestamp(){
        if(this.date !== ""){
          let date = new Date(this.date);
          switch (this.raffleType) {
            case "daily":{
              return date.valueOf();
            }
            case "weekly":{
              if (date.getUTCDay() !== 1) {
                return "You have to pick a monday for weekly raffles"
              }
              return date.valueOf();
            }
            case "monthly":{
              if (date.getUTCDate() !== 1) {
                return "You have to pick the 1st day of a month"
              }
              return date.valueOf();
            }
            default:{
              return ""
            }
          }
        }else
          return "";
      },
    },
    methods:{
      async fetchPrizes(raffleType) {
        if (raffleType !== "" && ["daily", "weekly", "monthly"].includes(raffleType) && /\d{13}/.test(this.timestamp)) {
          this.prizes = await Backend.getPrizes(raffleType, this.timestamp).then(res => {
            return res.prizes
          });
          console.log(this.prizes)
        }
      },
      humanTime(timestamp){
        let date = new Date(timestamp);
        return date.toUTCString();
      },
      async setClaimed(prize){
        if (prize._id) {
          alert(JSON.stringify(await Backend.claimPrizes([prize._id])));
          await this.fetchPrizes(this.raffleType);
        }
      },
      async remove(prize) {
        if (prize._id) {
          alert(JSON.stringify(await Backend.removePrizes([prize._id])));
          await this.fetchPrizes(this.raffleType);
        }
      },
      async applySettings() {
        let state = {
          buildings: this.buildings,
          roles: this.roles,
          usdToGemsRatio: this.usdToGemsRatio,
        }
        let result = await Backend.setState(state)
        console.log(result)
        if (result.error)
          alert(JSON.stringify(result))
        else {
          alert("new settings have been set successfully")
        }

      },
      async resetSettingseForm() {
        let state = await Backend.getState();
        if (state) {
          this.buildings = state.buildings;
          this.roles = state.roles;
          this.usdToGemsRatio = state.usdToGemsRatio;
        }
      },
      setGameFree(gameName){
        this.gamesUnlockedForAll.push(gameName);
        delete this.gamesGemUnlockPrice[gameName];
        delete this.gamesGemUnlockDiscount[gameName];
      },
      setGameLocked(){
        this.gamesUnlockedForAll.splice(this.gamesUnlockedForAll.indexOf(this.selectedGameName),1);
        this.gamesGemUnlockPrice[this.selectedGameName]=this.selectedGameUnlockPrice;
        this.gamesGemUnlockDiscount[this.selectedGameName]=this.selectedGameUnlockDiscount;
      },
    }
  }
</script>

<style scoped>
.flex-row{
  display: flex;
  flex-direction: row;
}

.flex-column{
  display: flex;
  flex-direction: column;
}
.page{
  height: 100%;
  width: 100%;
  position: relative;
  margin: 0 30px;
  overflow: auto;
}
form{
  text-align: left;
}
img{
  max-height: 180px;
}
.inputCell{
  min-width: 0;
  max-width: 90px;
}
.prize-card{
  margin-top: 10px;
  border: 1px black solid;
}

div{
  width: max-content;
}
</style>