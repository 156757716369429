<template>
  <div style="width: 100%">
    <h2>
      Coming soon
    </h2>
  </div>
</template>

<script>
import Backend from "@/classes/backend";
import {digitalPrizes} from "@/constants";

  export default {
    name: "PrizesPage",

    data() {
      return {
        newPrizeCode: '',
        page: 'add',
        subPage: 'prizes',
        raffleType: '',
        type: '',
        chain: '',
        inputContract: '',
        nftId: '',
        digitalType: '',
        auto: true,
        code: '',
        prizes: [],
        entries: [],
        date: "",
        useridInput: "",
        userId: "",
        totalNumEntries: 0,
        totalUsers: 0,
        totalRaffles: 0,
        openUser: false,
        selectedUserID: "",
        digitalPrizes: digitalPrizes,
        waxAssets: [],
        selectedAssets: [],
      }
    },
    computed: {
      userid() {
        if (this.useridInput === "")
          return null;
        return this.useridInput;
      },
      contract() {
        return this.chain === 'WAX' ? 'atomicassets' : this.inputContract;
      },
      timestamp() {
        if (this.date !== "") {
          let date = new Date(this.date);
          switch (this.raffleType) {
            case "daily": {
              return date.valueOf();
            }
            case "weekly":{
              if (date.getUTCDay() !== 1) {
                return "You have to pick a monday for weekly raffles"
              }
              return date.valueOf();
            }
            case "monthly":{
              if (date.getUTCDate() !== 1) {
                return "You have to pick the 1st day of a month"
              }
              return date.valueOf();
            }
            default:{
              return ""
            }
          }
        }else
          return "";
      },

    },
    methods: {
      async fetchPrizes(raffleType) {
        if (raffleType !== "" && ["daily", "weekly", "monthly"].includes(raffleType) && /\d{13}/.test(this.timestamp)) {
          this.prizes = await Backend.getPrizes(raffleType, this.timestamp).then(res => {
            return res.prizes
          });
          console.log(this.prizes)
        }
      },
      async fetchEntries(raffleType) {
        if (raffleType !== "" && ["daily", "weekly", "monthly"].includes(raffleType)) {
          let result = await Backend.getEntries(raffleType, this.timestamp, this.userid);

          if (result.error) {
            alert("Error:", result.error);
            return;
          }

          this.entries = result.raffles
          this.totalNumEntries = result.totalNumEntries
          this.totalUsers = result.totalUsers;
          this.totalRaffles = result.totalRaffles;

        }
      },
      humanTime(timestamp) {
        let date = new Date(timestamp);
        return date.toUTCString();
      },
      async setClaimed(prize) {
        if (prize._id) {
          alert(JSON.stringify(await Backend.claimPrizes([prize._id])));
          await this.fetchPrizes(this.raffleType);
        }
      },
      async remove(prize) {
        if (prize._id) {
          alert(JSON.stringify(await Backend.removePrizes([prize._id])));
          await this.fetchPrizes(this.raffleType);
        }
      },
      async addPrize() {
        let prizes = [];
        if (this.chain === "WAX") {
          let ids = this.nftId.match(/\d{13}/g)
          ids.forEach(nftid => {
            prizes.push({
              timestamp: this.timestamp,
              raffleType: this.raffleType,
              type: this.type,
              name: this.name,
              chain: this.chain,
              contract: this.contract,
              nftId: nftid,
              collection: this.collection,
              img: this.img,
              digitalType: this.digitalType,
              auto: this.auto,
              code: this.code,
            })
          })
        } else {
          prizes.push({
            timestamp: this.timestamp,
            raffleType: this.raffleType,
            type: this.type,
            name: this.name,
            chain: this.chain,
            contract: this.contract,
            nftId: this.nftId,
            collection: this.collection,
            img: this.img,
            digitalType: this.digitalType,
            auto: this.auto,
            code: this.code,
          })
        }
        console.log(prizes)

        let result = await Backend.addPrizes(this.timestamp, this.raffleType, prizes)
        console.log(result)
        if (result.error)
          alert(JSON.stringify(result))
        else {
          alert("prize was added successfully")
        }
      },
      resetAddPrizeForm() {
        this.chain = '';
        this.inputContract = '';
        this.nftId = '';
        this.digitalType = '';
        this.auto = true;
        this.code = '';
        this.prizes = [];
        this.date = "";
      },
      async setPrizeCode(prize) {
        if (prize._id) {
          alert(JSON.stringify(await Backend.setPrizeCode(prize._id, this.newPrizeCode)));
          await this.fetchPrizes(this.raffleType);
        }
      },
      async getAssets() {
        let assets = await Backend.getAssets();
        this.waxAssets = assets.data;
        this.waxAssets = this.waxAssets.sort((a, b) => {
          return parseInt(b.transferred_at_time) - parseInt(a.transferred_at_time)
        });
        return assets.data
      },
      toggleSelectAsset(id) {
        if (this.selectedAssets.includes(id)) {
          this.selectedAssets.splice(this.selectedAssets.indexOf(id), 1);
          this.nftId = this.nftId.replace(id, "")
          this.nftId = this.nftId.replace(/\s\s/g, "")
        } else {
          this.selectedAssets.push(id);
          this.nftId += id + " "
        }
      },
      isSelected(id) {
        return this.selectedAssets.includes(id)
      }
    }
  }
</script>

<style scoped>
.flex-row{
  display: flex;
  flex-direction: row;
}

.flex-column{
  display: flex;
  flex-direction: column;
}
.page{
  height: 100%;
  width: 100%;
  position: relative;
  margin: 0 30px;
  overflow: auto;
}

form {
  text-align: left;
}

img {
  max-height: 180px;
}

.prize-card {
  margin-top: 10px;
  border: 1px black solid;
}

.userPage {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  overflow: auto;
  background: white;
  margin: 0;
}

.closeUserPage {
  position: fixed;
  top: 80px;
  left: 0;
}

.assetsPicker {
  max-height: 600px;
  display: flex;
  overflow-y: auto;
  gap: 5px;
  border: black solid 1px;
  padding: 10px;
  width: 92%;
  flex-wrap: wrap;
  margin: 10px 0;
}

.assetCard {
  width: 120px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 5px;
  border: solid black 1px;
  cursor: pointer;
}

.assetCard > p {
  margin: 0;
}

.assetCard > img {
  max-height: unset;
}
</style>