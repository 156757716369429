<template>
  <div class="page flex-column">
    <h2>User:</h2>
    <div class="flex-row">
      <button @click="page='all'">
        Users
      </button>
      <button @click="page='emails'; getEmails();">
        Emails
      </button>
    </div>
    <div v-if="page==='all'" class="flex-column">
      <loading-comp v-if="isLoading"/>
      <h2>Users List</h2>
      <div class="flex-row" style="gap: 5px">
        <div>
          <p>Search:</p>
          <input v-model="searchInput">
        </div>
        <div>
          <p>Sort by:</p>
          <select v-model="sortBy">
            <option>created</option>
            <option>name</option>
            <option>chips</option>
            <option>gems</option>
            <option>experience</option>
            <option>dailyStreak</option>
          </select>
        </div>
        <div>
          <p>limit:</p>
          <select v-model.number="limit" @click="pageChanged=true">
            <option>5</option>
            <option>10</option>
            <option>25</option>
            <option>100</option>
            <option>500</option>
            <option>1000</option>
          </select>
        </div>
        <div>
          <p>Created before:</p>
          <input v-model="createdBeforeInput" type="date" name="Date">
        </div>
        <div>
          <p>Created after:</p>
          <input v-model="createdAfterInput" type="date" name="Date">
        </div>
        <div>
          <p>Ascending sort order:</p>
          <select v-model="sortByAscendingInput">
            <option>true</option>
            <option>false</option>
          </select>
        </div>
      </div>
      <br/>
      <div class="flex-row">
        <input value="" style="max-width: 20px"/>
        <input value="created" style="cursor: pointer" @click="sortBy='created'; resetGetUsers();"/>
        <input value="Username" style="cursor: pointer" @click="sortBy='name'; resetGetUsers();"/>
        <input value="Chips" style="cursor: pointer" @click="sortBy='chips'; resetGetUsers();"/>
        <input value="ggr" style="cursor: pointer" @click="sortBy='ggr'; resetGetUsers();"/>
        <input value="Gems" style="cursor: pointer" @click="sortBy='gems'; resetGetUsers();"/>
        <input value="XP" style="cursor: pointer" @click="sortBy='experience'; resetGetUsers();"/>
        <input value="Level" style="cursor: pointer; max-width: 50px;" @click="sortBy='experience'; resetGetUsers();"/>
        <input value="Streak" style="cursor: pointer; max-width: 50px;"
               @click="sortBy='dailyStreak'; resetGetUsers();"/>
        <input value="numReferred" style="cursor: pointer; max-width: 50px;"
               @click="sortBy='numReferred'; resetGetUsers();"/>
        <input value="referralCode"/>
        <input value="referrer"/>
        <input value="id"/>
      </div>
      <div>
        <div v-for="(user, index) in filterdUsersList"
             :key="index"
             class="flex-row"
        >
          <div @click="getUser(user._id);" style="cursor: pointer; min-width: 28px;">🖊️</div>
          <input :value="humanTime(user.created)"/>
          <input :value="user.username"/>
          <input :value="user.chips"/>
          <input :value="user.ggr"/>
          <input :value="user.gems"/>
          <input :value="user.experience"/>
          <input :value="level(user.experience)" style="max-width: 50px;"/>
          <input :value="user.dailyStreak" style="max-width: 50px;"/>
          <input :value="user.numReferred" style="max-width: 50px;"/>
          <input :value="user.referralCode"/>
          <input @click="getUser(user.referrer);" style="cursor: pointer;" :value="referrer(user.referrer)"/>
          <input :value="user._id"/>
        </div>
      </div>
      <div>
        <br/>
        Page:
        <button v-if="pageNumber>1" @click="pageNumber--; pageChanged=true">previous</button>
        {{ pageNumber }}/{{ totalPages }}
        <button v-if="pageNumber<totalPages" @click="pageNumber++; pageChanged=true">next</button>
        <br/>
        <br/>
        showing {{ filterdUsersList.length }} out of {{ total }}
      </div>
    </div>
    <div v-if="page==='emails'" class="flex-column">
      <loading-comp v-if="isLoading"/>
      <h2>Emails List</h2>
      <br/>
      <div class="flex-row" style="gap: 5px">
        <div>
          <button @click="downloadEmailsFull()"><img v-if="isDownloadLoading" src="../../assets/loading.gif"
                                                     style="height: 10px;"/> Download Emails with ids
          </button>
        </div>
        <div>
          <button @click="downloadEmails()"><img v-if="isDownloadLoading" src="../../assets/loading.gif"
                                                 style="height: 10px;"/> Download Emails
          </button>
        </div>
      </div>
      <p style="text-align: left">Number of emails: {{ emailsList.length }}</p>
      <div class="flex-row">
        <input value="" style="max-width: 20px"/>
        <input value="id" style="cursor: pointer"/>
        <input value="email"/>
      </div>
      <div>
        <div v-for="(user, index) in emailsList"
             :key="index"
             class="flex-row"
        >
          <div @click="getUser(user.userId);" style="cursor: pointer; min-width: 28px;">🖊️</div>
          <input :value="user.userId" style="cursor: pointer;" @click="getUser(user.userId);"/>
          <input :value="user.email"/>
        </div>
      </div>
      <div>
        <br/>
        Page:
        <button v-if="pageNumber>1" @click="pageNumber--; pageChanged=true">previous</button>
        {{ pageNumber }}/{{ totalPages }}
        <button v-if="pageNumber<totalPages" @click="pageNumber++; pageChanged=true">next</button>
        <br/>
        <br/>
        showing {{ filterdUsersList.length }} out of {{ total }}
      </div>
    </div>
    <form v-else class="flex-column">

      <div>
        <button v-if="isUsersPage" @click="this.page='all'">Return</button>
        <h2>User {{ page }}</h2>
      </div>

      <div>
        <img v-if="userData.avatar" :src="userData.avatar" alt="" style="width: 100px"/>
        <p>ID: {{ page }}</p>

        <input value="Username:" disabled="true"/>
        <input v-model.number="userData.username" placeholder="Username"/>
        <div>
          <input value="Avatar:" disabled="true"/>
          <input v-model.number="userData.avatar" placeholder="Discord not connected"/>
        </div>
        <div>
          <input value="Discord User ID:" disabled="true"/>
          <input v-model.number="userData.discordUserId" placeholder="not connected"/>
        </div>
        <div>
          <input value="Google User ID:" disabled="true"/>
          <input v-model.number="userData.googleUserId" placeholder="not connected"/>
        </div>
        <div>
          <input value="Facebook User ID:" disabled="true"/>
          <input v-model.number="userData.fbUserId" placeholder="not connected"/>
        </div>
        <br/>
        <div>
          <input value="Created: " disabled="true"/>
          <input v-model.number="userData.created" placeholder="created"/>
          {{ humanTime(userData.created) }}
        </div>
        <div>
          <input value="Referrer: " disabled="true"/>
          <input v-model.number="userData.referrer" placeholder="None"/>
        </div>
        <div>
          <input value="Number of referrals: " disabled="true"/>
          <input v-model.number="userData.numReferred" placeholder="0"/>
        </div>
        <div>
          <input value="Referral Code: " disabled="true"/>
          <input v-model.number="userData.referralCode" placeholder="referralCode"/>
        </div>

        <br/>
        <div>
          <input value="Chips: " disabled="true"/>
          <input v-model.number="userData.chips" placeholder="chips"/>
        </div>
        <div>
          <input value="Gems: " disabled="true"/>
          <input v-model.number="userData.gems" placeholder="gems"/>
        </div>
        <div>
          <input value="Experience: " disabled="true"/>
          <input v-model.number="userData.experience" placeholder="experience"/>
        </div>
        <div>
          <input value="Daily Streak: " disabled="true"/>
          <input v-model.number="userData.dailyStreak" placeholder="dailyStreak"/>
        </div>
        <div>
          <input value="Claimed Free Chips Today:" disabled="true"/>
          <input v-model.number="userData.claimedFreeChipsToday" placeholder="claimedFreeChipsToday"/>
        </div>
        <div>
          <input value="Last Free Chips Claim Date: " disabled="true"/>
          <input v-model.number="userData.lastFreeChips" placeholder="lastFreeChips"/>
          {{ humanTime(userData.lastFreeChips) }}
        </div>

        <br/>
        <div>
          <input value="Bronze Tickets: " disabled="true"/>
          <input v-model.number="userData.bronzeTickets" placeholder="bronzeTickets"/>
        </div>
        <div>
          <input value="Silver Tickets:" disabled="true"/>
          <input v-model.number="userData.silverTickets" placeholder="silverTickets"/>
        </div>
        <div>
          <input value="Gold Tickets:" disabled="true"/>
          <input v-model.number="userData.goldTickets" placeholder="goldTickets"/>
        </div>
        <br/>
      </div>
    </form>
  </div>
</template>

<script>
import Backend from "@/classes/backend";
import loadingComp from "@/components/LoadingComp";
import {referrers} from "@/constants";
import backend from "@/classes/backend";

export default {
  name: "UsersPage",
  components: {
    loadingComp,
  },
  props: {
    userid: {
      type: String,
      required: false,
      default: "all"
    },
  },
  data() {
    return {
      total: 0,
      limit: 500,
      searchInput: "",
      sortBy: "created",
      usersList: [],
      emailsList: [],
      usersMap: {},
      sortByAscendingInput: "false",
      page: this.userid,
      pageNumber: 1,
      createdAfterInput: 0,
      createdBeforeInput: 0,
      pageChanged: false,
      userData: {},
      xpPerLevelIncreasePercentage: 15,
      isLoading: true,
      isDownloadLoading: false,
    }
  },
  async mounted() {
    if (this.userid === 'all') {
      this.resetGetUsers();
      console.log(this.usersList)
    } else {
      this.getUser(this.userid)
    }
    this.xpPerLevelIncreasePercentage = await Backend.getState().then(res => {
      return res.xpPerLevelIncreasePercentage
    })
  },
  watch: {
    limit() {
      this.usersList = [];
      this.resetGetUsers();
    },
  },
  computed: {
    createdAfter() {
      if (this.createdAfterInput && this.createdAfterInput !== 0)
        return new Date(this.createdAfterInput).valueOf()
      return 0
    },
    createdBefore() {
      if (this.createdBeforeInput && this.createdBeforeInput !== 0)
        return new Date(this.createdBeforeInput).valueOf()
      return new Date().valueOf()
    },
    totalPages() {
      return Math.ceil(this.total / this.limit);
    },
    sortByAscending() {
      return JSON.parse(this.sortByAscendingInput)
    },
    filterdUsersList() {
      if (this.pageChanged && this.limit < this.total)
        this.resetGetUsers()
      return this.usersList.filter(user =>
          (user.username.toLowerCase().includes(this.searchInput.toLowerCase()) ||
              user._id.toLowerCase().includes(this.searchInput.toLowerCase()) ||
              user.referralCode.toLowerCase().includes(this.searchInput.toLowerCase()) ||
              (user.referrer && user.referrer.toLowerCase().includes(this.searchInput.toLowerCase())) ||
              this.searchInput === "") && user.created > this.createdAfter && user.created < this.createdBefore
      )
          .sort((a, b) =>
              (this.sortBy === "created" ? a.created - b.created :
                  this.sortBy === "name" ? a.username.localeCompare(b.username, 'en', {sensitivity: 'base'}) :
                      this.sortBy === "chips" ? a.chips - b.chips :
                          this.sortBy === "gems" ? a.gems - b.gems :
                              this.sortBy === "experience" ? a.experience - b.experience :
                                  this.sortBy === "dailyStreak" ? a.dailyStreak - b.dailyStreak :
                                      a._id - b._id) * (this.sortByAscending ? 1 : -1)
          ).slice(0, this.limit);
    },
    isUsersPage() {
      return window.location.href.includes("/users")
    }
  },
  methods: {
    async resetGetUsers() {
      this.isLoading = true;
      let usersList = await Backend.getUsers(this.limit, this.pageNumber, this.sortBy, this.sortByAscending, this.createdAfter, this.createdBefore);
      this.pageChanged = false;
      if (usersList.total > 0) {
        this.usersList = usersList.users;
        this.total = usersList.total;
        this.usersList.forEach(user => {
          this.usersMap[user._id] = user;
        })
      }
      this.isLoading = false;
      return usersList.users;
    },
    level(xp) {
      let xpForCurrentLevel = 1000;
      let xpPerLevelIncrease = 1000;

      for (let i = 0; i < 100; i++) {
        if (xp < xpForCurrentLevel) {
          return i;
        }
        xpPerLevelIncrease = Math.round(xpPerLevelIncrease * (1 + this.xpPerLevelIncreasePercentage / 100));
        xpForCurrentLevel += xpPerLevelIncrease;
      }
      return 100;
    },
    humanTime(timestamp) {
      let date = new Date(timestamp);
      return date.toUTCString();
    },
    async getUser(userID) {
      this.isLoading = true;
      this.userData = await Backend.getUser(userID)
      this.isLoading = false;
      this.page = userID;
    },
    async getEmails() {
      this.isLoading = true;
      console.log("getting emails")
      this.emailsList = await Backend.getEmailsWithUserids()
      this.emailsList.reverse();
      this.isLoading = false;
    },
    referrer(userid) {
      if (userid && referrers[userid])
        return referrers[userid]
      if (userid && this.usersMap[userid])
        return this.usersMap[userid].username
      // if (userid)
      //   return await Backend.getUser(userid).then(user =>{
      //     return user.username
      //   })
      return userid
    },
    async downloadEmails() {
      this.isDownloadLoading = true;
      var text = JSON.stringify(await backend.getEmails(), null, 1);
      var filename = "Emails.txt";

      var blob = new Blob([text], {type: 'text/plain'});
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;

      // This is necessary as Firefox doesn't allow the click method to be called programatically.
      link.style.display = 'none';
      document.body.appendChild(link);

      link.click();

      // Remove the link when done
      document.body.removeChild(link);
      this.isDownloadLoading = false;
    },
    async downloadEmailsFull() {
      this.isDownloadLoading = true;
      var text = JSON.stringify(this.emailsList, null, 1);
      var filename = "EmailsWithIDs.txt";

      var blob = new Blob([text], {type: 'text/plain'});
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;

      // This is necessary as Firefox doesn't allow the click method to be called programatically.
      link.style.display = 'none';
      document.body.appendChild(link);

      link.click();

      // Remove the link when done
      document.body.removeChild(link);
      this.isDownloadLoading = false;
    }
  }
}
</script>

<style scoped>

.page {
  height: 100%;
  width: 100%;
  position: relative;
  margin: 0 30px;
  overflow: auto;
}

form {
  text-align: left;
}
</style>