export const languages = [
  {
    icon: 'flag-en.png',
    label: 'EN',
    type: 'en',
  },
  /*{
    icon: 'flag-cn.png',
    label: 'CN',
    type: 'cn',
  }*/
];

export const currencies = [
  {
    icon: 'icon-eos.png',
    label: 'EOS',
    type: 'EOS'
  },
  {
    icon: 'icon-kylin.png',
    label: 'KYLIN',
    type: 'KYLIN'
  },
  {
    icon: 'icon-wax.svg',
    label: 'WAX',
    type: 'WAX'
  },
  {
    icon: 'icon-sol.svg',
    label: 'SOL',
    type: 'SOLANA'
  },
  {
    icon: 'icon_polygon.svg',
    label: 'MATIC',
    type: 'Polygon'
  },
  {
    icon: 'icon_bnb.svg',
    label: 'BNB',
    type: 'BNB'
  },
];

export const wallets = [
  {
    name: 'scatter',
    displayName: 'Scatter'
  },
  {
    name: 'anchor',
    displayName: 'Anchor'
  },
];

export const userSettings = [
  {
    icon: 'icon-wallet',
    label: 'Wallet',
    type: 'wallet',
  },
  {
    icon: 'logout',
    label: 'Logout',
    type: 'logout',
  },
];

  export const allGames = {
  kingarthur: {
    title: 'King Arthur',
    name: 'kingarthur',
    uri: 'https://blockspingaming.com/king-arthur',
    description:
      'kingarthur_desc',
    image: 'king-arthur.png',
    thumb: 'king-arthur-thumb.png',
    isNew: false,
    order: 1,
  },
  wildwest: {
    title: 'Wild West',
    name: 'wildwest',
    uri: 'https://blockspingaming.com/wild-west',
    description:
      'wildwest_desc',
    image: 'wildwest.png',
    thumb: 'wildwest-thumb.png',
    isNew: false,
    order: 5
  },
  nightoffear: {
    title: 'Night of Fear',
    name: 'nightoffear',
    uri: 'https://blockspingaming.com/night-of-fear',
    description:
      'nightoffear_desc',
    image: 'night-of-fear.png',
    thumb: 'night-of-fear-thumb.png',
    isNew: false,
    order: 3
  },
  cartel: {
    title: 'Cartel',
    name: 'cartel',
    uri: 'https://blockspingaming.com/cartel',
    description:
      'cartel_desc',
    image: 'cartel.png',
    thumb: 'cartel-thumb.png',
    isNew: false,
    order: 4
  },
  tigerland: {
    title: 'Tiger Land',
    name: 'tigerland',
    uri: 'https://blockspingaming.com/tigerland',
    description:
      'tigerland_desc',
    image: 'tigerland.png',
    thumb: 'tigerland-thumb.png',
    isNew: false,
    order: 2
  },
  cleopatra: {
    title: 'Cleopatra',
    name: 'cleopatra',
    uri: 'https://blockspingaming.com/cleopatra',
    description:
      'cleopatra_desc',
    image: 'cleopatra.png',
    thumb: 'cleopatra-thumb.png',
    isNew: false,
    order: 6
  },
  spymaster: {
    title: 'Spymaster',
    name: 'spymaster',
    uri: 'https://blockspingaming.com/spymaster',
    description:
      'spymaster_desc',
    image: 'spymaster.png',
    thumb: 'spymaster-thumb.png',
    isNew: false,
    order: 7
  },
  samurai: {
    title: 'Samurai Spirit',
    name: 'samurai',
    uri: 'https://blockspingaming.com/samurai-spirit',
    description:
      'samurai_desc',
    image: 'samurai.png',
    thumb: 'samurai-thumb.png',
    isNew: false,
    order: 8
  },
  surfin: {
    title: 'Surfin\' California',
    name: 'surfin',
    uri: 'https://blockspingaming.com/surfin',
    description:
      'surfin_desc',
    image: 'surfin.png',
    thumb: 'surfin-thumb.png',
    isNew: false,
    order: 9
  },
  pirates: {
    title: 'Treasure Island Quest',
    name: 'pirates',
    uri: 'https://blockspingaming.com/treasure-island',
    description:
      'pirates_desc',
    image: 'pirates.png',
    thumb: 'pirates-thumb.png',
    isNew: false,
    order: 10
  },
  thebigfive: {
    title: 'The Big Five',
    name: 'thebigfive',
    uri: 'https://blockspingaming.com/thebig5',
    description:
      'thebigfive_desc',
    image: 'thebigfive.png',
    thumb: 'thebigfive-thumb.png',
    isNew: false,
    order: 11
  },
  aladdin: {
    title: 'Aladdin',
    name: 'aladdin',
    uri: 'https://blockspingaming.com/aladdin',
    description:
        'aladdin_desc',
    image: 'aladdin.png',
    thumb: 'aladdin-thumb.png',
    isNew: false,
    order: 12
  },
  };

export const allSymbols = {
  kingarthur: {
    Wild: 1,
    FreeSpins: 2,
    Scatter: 3,
    King: 4,
    Wizard: 5,
    Witch: 6,
    Knight: 7,
    Shield: 8,
    A: 9,
    K: 10,
    Q: 11,
    J: 12,
    Ten: 13
  },
  wildwest: {
    Wild: 1,
    FreeSpins: 2,
    Scatter: 3,
    Cowgirl: 4,
    Cowboy: 5,
    Bandit: 6,
    Gun: 7,
    Hat: 8,
    A: 9,
    K: 10,
    Q: 11,
    J: 12,
    Ten: 13
  },
  nightoffear: {
    Wild: 1,
    Bonus: 2,
    Scatter: 3,
    Vampire: 4,
    Werewolf: 5,
    Mummy: 6,
    Phantom: 7,
    A: 8,
    K: 9,
    Q: 10,
    J: 11,
    Ten: 12
  },
  cartel: {
    Wild: 1,
    Bonus: 2,
    Scatter: 3,
    DEA: 4,
    Escobar: 5,
    Sicario: 6,
    Helicopter: 7,
    Boat: 8,
    A: 9,
    K: 10,
    Q: 11,
    J: 12
  },
  tigerland: {
    Wild: 1,
    Coin: 2,
    Scatter: 3,
    Wall: 4,
    Male: 5,
    Female: 6,
    Lotus: 7,
    Vase: 8,
    A: 9,
    K: 10,
    Q: 11,
    J: 12
  },
  cleopatra: {
    Wild: 1,
    Scatter: 2,
    Coin: 2,
    Cleopatra: 3,
    Pharaoh: 4,
    Cobra: 5,
    Cat: 6,
    Cross: 7,
    Palm: 8,
    A: 9,
    K: 10,
    Q: 11,
    J: 12
  },
  spymaster: {
    Wild: 1,
    Bonus: 2,
    Scatter: 3,
    Blonde: 4,
    Brunette: 5,
    Yacht: 6,
    Diamond: 7,
    Car: 8,
    A: 9,
    K: 10,
    Q: 11,
    J: 12
  },
  samurai: {
    Wild: 1,
    Scatter: 2,
    Samurai: 3,
    SamuraiGirl: 4,
    Katana: 5,
    Bonzai: 6,
    GoldenHammer: 7,
    Umbrella: 8,
    A: 9,
    K: 10,
    Q: 11,
    J: 12
  },
  surfin: {
    Wild: 1,
    Bonus: 2,
    Scatter: 2,
    SurferGirl: 3,
    SurfBoard: 4,
    Parasol: 5,
    Star: 6,
    Shell: 7,
    A: 8,
    K: 9,
    Q: 10,
    J: 11,
    Ten: 12
  },
  pirates: {
    Wild: 1,
    Scatter: 2,
    Coin: 2,
    Captain: 3,
    Man: 4,
    Woman: 5,
    Parrot: 6,
    Sword: 7,
    Bottle: 8,
    A: 9,
    K: 10,
    Q: 11,
    J: 12,
    Green: 13,
    Blue: 14,
    Yellow: 15,
    Red: 16,
    Violet: 17
  },
  thebigfive: {
    Wild: 1,
    FreeSpins: 2,
    Scatter: 3,
    King: 4,
    Wizard: 5,
    Witch: 6,
    Knight: 7,
    Shield: 8,
    A: 9,
    K: 10,
    Q: 11,
    J: 12,
    Ten: 13
  },
  aladdin: {
    Wild: 1,
    Bonus: 2,
    Scatter: 3,
    Vampire: 4,
    Werewolf: 5,
    Mummy: 6,
    Phantom: 7,
    A: 8,
    K: 9,
    Q: 10,
    J: 11,
    Ten: 12
  },
}

export const allAchievements = {
  chad: {
    title: 'Slots Chad',
    name: 'chad',
    description: 'Earning all the badges',
    image: 'chad.png',
  },
  trophy: {
    title: 'Member Trophy',
    name:'master',
    description:'Take part in a Discord event',
    image:'trophy.png',
  },
  master:{
    title:'Slots Master',
    name:'master',
    description:'Winning 10,000 slots games',
    image:'master.png',
  },
  trainwreck:{
    title:'Slots Trainwreck',
    name:'trainwreck',
    description:'Losing 10,000 slots games',
    image:'trainwreck.png',
  },
  slotsohollic:{
    title:'Slotsohollic',
    name:'slotsohollic',
    description:'Playing 10,000 slots games',
    image:'slotsohollic.png',
  },
  vip:{
    title:'VIP',
    name:'vip',
    description:'Spending more than $100 on chips',
    image:'vip.png',
  },
  newyear:{
    title:'New Year 2023, New Me',
    name:'newyear2023',
    description:'Playing a game in the last week of 2022',
    image:'newyear2023.png',
  },
  streak100:{
    title:'100 streak',
    name:'streak100',
    description:'100 daily streak',
    image:'streak100.png',
  },
  bigwinner:{
    title:'Big winner',
    name:'bigwinner',
    description:'Win at least 10,000 chips in one spin',
    image:'bigwinner.png',
  },
  chickendinner:{
    title:'Winner winner chicken dinner',
    name:'chickendinner',
    description:'Winning the Jackpot',
    image:'chickendinner.png',
  },
  excalibur:{
    title:'Excalibur',
    name:'excalibur',
    description:'10,000 bets on king arthar',
    image:'excalibur.png',
  },
  outlaw:{
    title:'Outlaw',
    name:'outlaw',
    description:'10,000 bets on Wild West',
    image:'outlaw.png',
  },
  kingpin:{
    title:'Narcos kingpin',
    name:'kingpin',
    description:'10,000 bets on Cartel',
    image:'kingpin.png',
  },
  zhuhou:{
    title:'Zhuhou',
    name:'zhuhou',
    description:'10,000 bets on Tiger land',
    image:'zhuhou.png',
  },
  ghost:{
    title:'Ghost',
    name:'ghost',
    description:'10,000 bets on night of fear',
    image:'ghost.png',
  },
  scarabs:{
    title:'Golden Scarabs',
    name:'scarabs',
    description:'10,000 bets on Cleopatra',
    image:'scarabs.png',
  },
  topsecret:{
    title:'Top Secret',
    name:'topsecret',
    description:'10,000 bets on SpyMaster',
    image:'topsecret.png',
  },
  shogun:{
    title:'Shogun',
    name:'shogun',
    description:'10,000 bets on Samurai Spring',
    image:'shogun.png',
  },
  surfer:{
    title:'Silver Surfer',
    name:'surfer',
    description:'10,000 bets on Surfin\' California',
    image:'surfer.png',
  },
  pirate:{
    title:'Pirate treasure',
    name:'pirate',
    description:'10,000 bets on Treasure Island Quest',
    image:'pirate.png',
  },
  jungleking:{
    title:'King of the jungle',
    name:'jungleking',
    description:'10,000 bets on The Big Five',
    image:'jungleking.png',
  },
  genie:{
    title:'Genie',
    name:'genie',
    description:'10,000 bets on Aladdin',
    image:'genie.png',
  },
}

export const digitalPrizes = {
  'nitrofull':{
    name: "Full Nitro",
    collection: "Discord",
    codeInstructions: "- Clicking the Promo Link/Copy and Pasting into your Browser Window\n" +
      "If you're not already logged into Discord in your browser, you will then be automatically redirected to login or" +
      " register window\n" +
      "Once you have logged into your Discord account, you'll see a new message asking you to confirm that you are" +
      " accepting the Nitro subscription on the correct Discord account.\n" +
      "press the \"Accept Gift\" button.",
    img: "nitro-full.jpg"
  },
  'nitroclassic': {
    name: "Classic Nitro",
    collection: "Discord",
    codeInstructions: "- Clicking the Promo Link/Copy and Pasting into your Browser Window\n" +
      "If you're not already logged into Discord in your browser, you will then be automatically redirected to login or" +
      " register window\n" +
      "Once you have logged into your Discord account, you'll see a new message asking you to confirm that you are" +
      " accepting the Nitro subscription on the correct Discord account.\n" +
      "press the \"Accept Gift\" button.",
    img: "nitro-classic.jpg"
  },
  'csdeals': {
    name: "Giftcard",
    collection: "CS.Deals",
    codeInstructions: "Here is how to use your code",
    img: "giftcard-csdeals.jpg"
  },
  'g2a': {
    name: "Giftcard",
    collection: "G2A",
    codeInstructions: "Here is how to use your code",
    img: "giftcard-g2a.jpg"
  },
  'nintendo5': {
    name: " Giftcard",
    collection: "Nintendo",
    codeInstructions: "Here is how to use your code",
    img: "giftcard-nintendo5.png"
  },
  'nintendo10': {
    name: "Giftcard",
    collection: "Nintendo",
    codeInstructions: "Here is how to use your code",
    img: "giftcard-nintendo10.png"
  },
  'nintendo20': {
    name: "Giftcard",
    collection: "Nintendo",
    codeInstructions: "Here is how to use your code",
    img: "giftcard-nintendo20.png"
  },
  'roblox': {
    name: "Giftcard",
    collection: "Roblox",
    codeInstructions: "Here is how to use your code",
    img: "giftcard-roblox.jpg"
  },
  'steam': {
    name: "Giftcard",
    collection: "Steam",
    codeInstructions: "Here is how to use your code",
    img: "giftcard-steam.jpg"
  },
  'bronzeMonthlyCard': {
    name: "bronze Monthly Card",
    collection: "Blockspin Gaming",
    codeInstructions: "Go to the homepage, click 'Chips' button, click redeem, past your redeem code then click 'Send Code'.",
    img: "vip-bronze.png"
  },
  'silverMonthlyCard': {
    name: "Silver Monthly Card",
    collection: "Blockspin Gaming",
    codeInstructions: "Go to the homepage, click 'Chips' button, click redeem, past your redeem code then click 'Send Code'.",
    img: "vip-silver.png"
  },
  'goldMonthlyCard': {
    name: "Gold Monthly Card",
    collection: "Blockspin Gaming",
    codeInstructions: "Go to the homepage of Blockspin Gaming website, click 'Chips' button, click redeem, past your redeem code then click 'Send Code'.",
    img: "vip-gold.png"
  },
}

export const titles = {
  0: "Newbie",
  10: "Rookie",
  20: "Original Sloter",
  30: "Total Slotter",
  40: "Amazing Slotter",
  50: "Wow Slotter",
  60: "Pro Slotter",
  69: "Nice Slotter",
  80: "Godlike",
  90: "Tryhard Gamer",
  100: "The GOAT!",
};

export const referrers = {
  "63a2d81c1b860afc59fad6e1": "Jackbot",
  "63a3bcee72cc378e0e4eb3ae": "Adsterra",
  "63a3d82a72cc378e0e4eb9e3": "email campaign",
  "63a6df81548c0e6b5c034096": "blog",
  "63a330140f288788481a385f": "topgg",
};

export const scratchCards = {
  panda: {
    title: 'Panda Party',
    name: 'panda',
    uri: 'https://blockspingaming.com/panda',
    description:
        'scratch_high_vol_desc',
    image: 'panda.png',
    thumb: 'panda.png',
    cardPrice: 10,
    diamondWin: 10000,
    isNew: false,
    order: 1,
  },
  buffalo: {
    title: 'Buffalo Mania',
    name: 'buffalo',
    uri: 'https://blockspingaming.com/buffalo',
    description:
        'scratch_high_vol_desc',
    image: 'buffalo.png',
    thumb: 'buffalo.png',
    cardPrice: 20,
    diamondWin: 20000,
    isNew: false,
    order: 2
  },
  hippo: {
    title: 'Hippo Rama',
    name: 'hippo',
    uri: 'https://blockspingaming.com/hippo',
    description:
        'scratch_high_vol_desc',
    image: 'hippo.png',
    thumb: 'hippo.png',
    cardPrice: 50,
    diamondWin: 50000,
    isNew: false,
    order: 3
  },
  cheetah: {
    title: 'Cheetah Chasers',
    name: 'cheetah',
    uri: 'https://blockspingaming.com/cheetah',
    description:
        'scratch_high_vol_desc',
    image: 'cheetah.png',
    thumb: 'cheetah.png',
    cardPrice: 100,
    diamondWin: 100000,
    isNew: false,
    order: 4
  },
  rhino: {
    title: 'Rhino Rampage',
    name: 'rhino',
    uri: 'https://blockspingaming.com/rhino',
    description:
        'scratch_very_high_vol_desc',
    image: 'rhino.png',
    thumb: 'rhino.png',
    cardPrice: 200,
    diamondWin: 250000,
    isNew: false,
    order: 5
  },
  elephant: {
    title: 'Elephant Frenzy',
    name: 'elephant',
    uri: 'https://blockspingaming.com/elephant',
    description:
        'scratch_very_high_vol_desc',
    image: 'elephant.png',
    thumb: 'elephant.png',
    cardPrice: 500,
    diamondWin: 500000,
    isNew: false,
    order: 6
  },
  lion: {
    title: 'Lion\'s Roar',
    name: 'lion',
    uri: 'https://blockspingaming.com/lion',
    description:
        'scratch_very_high_vol_desc',
    image: 'lion.png',
    thumb: 'lion.png',
    cardPrice: 1000,
    diamondWin: 1000000,
    isNew: false,
    order: 7
  },
  tiger: {
    title: 'Tiger Strike',
    name: 'tiger',
    uri: 'https://blockspingaming.com/tiger',
    description:
        'scratch_very_high_vol_desc',
    image: 'tiger.png',
    thumb: 'tiger.png',
    cardPrice: 5000,
    diamondWin: 2000000,
    isNew: false,
    order: 8
  },

};

