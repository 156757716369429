<template>
  <div class="page flex-column">
    <h2>
      Coming soon
    </h2>
  </div>
</template>

<script>
import Backend from "@/classes/backend";
import {allGames} from "@/constants";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "StatsPage",
  components: {
  },
  data() {
    return {
      page: "general",
      // state
      jackpot: 0,
      lastLeaderboardPaid: 1670371200000,
      lastRaffleDrawn: 1670371200000,
      // automatically set state
      waxUsdPrice: 100,
      maticUsdPrice: 100,
      bnbUsdPrice: 100,
      solUsdPrice: 100,
      gameInput: "all",
      gameStats: null,
      timestampInput: "",
      timestampFromInput: "",
      dateFrom: "",
      allGames: allGames,
      numberOfDays: 1,


      total: 0,
      limit: 1000,
      searchInput: "",
      sortBy: "created",
      usersList: [],
      sortByAscendingInput: "true",
      pageNumber: 1,
      createdAfterInput: "",
      createdBeforeInput: "",
      pageChanged: false,
      userData: {},
      dataFrequencyInput: "daily",
      isLoading: true,
      isUsersLoading: true,
      chipsTable: {},
      isChipsLoading: true,
      totalChips: 0,
      totalGems: 0,
      totalBet: 0,
      totalWon: 0,
      totalClaimedFreeChips: 0,
      betsBalance: 0,
      totalLeaderboard: 0,
      otherFreeChips: 0,
      chipStatsData: [],
      chartOptions: {
        responsive: true
      },
    }
  },
  computed: {
    dataFrequency() {
      switch (this.dataFrequencyInput) {
        case "daily": {
          return 1000 * 60 * 60 * 24;
        }
        case "weekly": {
          return 1000 * 60 * 60 * 24 * 7;
        }
        case "monthly": {
          return 1000 * 60 * 60 * 24 * 30;
        }
        default:
          return 1000 * 60 * 60 * 24;
      }
    },
    game() {
      if (this.gameInput === "all")
        return null;
      return this.gameInput;
    },
    timestamp() {
      if (this.timestampInput && this.timestampInput !== "") {
        return new Date(this.timestampInput).valueOf() + 1000 * 60 * 60 * 24
      }
      return new Date().valueOf() - new Date().valueOf() % (1000 * 60 * 60 * 24);
    },
    fromTimestamp() {
      if (this.timestampFromInput && this.timestampFromInput !== "") {
        return new Date(this.timestampFromInput).valueOf();
      }
      return null;
    },
    createdAfter() {
      if (this.createdAfterInput && this.createdAfterInput !== "")
        return new Date(this.createdAfterInput).valueOf()
      return 0;
    },
    createdBefore() {
      if (this.createdBeforeInput && this.createdBeforeInput !== 0)
        return new Date(this.createdBeforeInput).valueOf()
      return new Date().valueOf()
    },
    newUsersTable() {
      let output = {};
      this.usersList.forEach(user => {
        let timestamp = user.created - user.created % this.dataFrequency;
        if (output[timestamp]) {
          output[timestamp].total++;
        } else {
          output[timestamp] = {total: 1};
        }
      })
      console.log(output)
      return output;
    },
  },
  mounted() {
    this.resetGetState();
  },
  methods: {
    async resetGetState() {
      this.isLoading = true;
      let state = await Backend.getState();
      this.isLoading = false;
      if (state) {
        this.jackpot = state.jackpot;
        this.lastLeaderboardPaid = state.lastLeaderboardPaid;
        this.lastRaffleDrawn = state.lastRaffleDrawn;
        this.waxUsdPrice = state.waxUsdPrice;
        this.maticUsdPrice = state.maticUsdPrice;
        this.bnbUsdPrice = state.bnbUsdPrice;
        this.solUsdPrice = state.solUsdPrice;
      }
    },
    async getGameStates() {
      this.numberOfDays = 1;
      if (this.fromTimestamp) {
        console.log(this.timestampFromInput, this.fromTimestamp)
        let isStatsReset = false;
        let startT = this.fromTimestamp + 1000 * 60 * 60 * 24;
        while (startT <= this.timestamp) {
          console.log(this.humanTime(startT), this.numberOfDays)
          if (startT - this.timestamp <= 1000 * 60 * 60 * 24 * 30) {
            this.isLoading = true;
            let dateStats = await Backend.getGameStats(startT, this.game);
            this.isLoading = false;
            if (dateStats.all) {
              if (!isStatsReset) {
                this.gameStats = dateStats;
                this.dateFrom = this.humanTime(startT - 1000 * 60 * 60 * 24);
                isStatsReset = true;
              } else {
                this.numberOfDays++;
                Object.entries(dateStats).forEach((dateGameStat) => {
                  let dateGameName = dateGameStat[0];
                  dateGameStat = dateGameStat[1];
                  this.gameStats[dateGameName].numBets += dateGameStat.numBets ?? 0;
                  this.gameStats[dateGameName].numBigWins += dateGameStat.numBigWins ?? 0;
                  this.gameStats[dateGameName].numBonus += dateGameStat.numBonus ?? 0;
                  this.gameStats[dateGameName].numFreeSpins += dateGameStat.numFreeSpins ?? 0;
                  this.gameStats[dateGameName].numJackpotWins += dateGameStat.numJackpotWins ?? 0;
                  this.gameStats[dateGameName].numWins += dateGameStat.numWins ?? 0;
                  this.gameStats[dateGameName].numLosses += dateGameStat.numLosses ?? 0;
                  this.gameStats[dateGameName].totalBet += dateGameStat.totalBet ?? 0;
                  this.gameStats[dateGameName].totalWon += dateGameStat.totalWon ?? 0;
                  this.gameStats[dateGameName].dailyActive += dateGameStat.dailyActive ?? 0;
                  this.gameStats[dateGameName].weeklyActive += dateGameStat.weeklyActive ?? 0;
                  this.gameStats[dateGameName].monthlyActive += dateGameStat.monthlyActive ?? 0;
                  this.gameStats[dateGameName].timestamp = startT;
                })
              }
            }
            startT += 1000 * 60 * 60 * 24;
          } else {
            alert("Error: you can't select a range longer than 30 days");
            startT = this.timestamp - 1000 * 60 * 60 * 24 * 30
          }
        }

      } else {
        this.isLoading = true;
        this.gameStats = await Backend.getGameStats(this.timestamp, this.game).then(res => {
          if (res.error) {
            window.alert(JSON.stringify(res));
            return null;
          }
          return res;
        })
        this.isLoading = false;
      }
      console.log(this.gameStats)
    },
    humanTime(timestamp) {
      let date = new Date(parseInt(timestamp));
      return date.toUTCString();
    },

    async getUsers() {
      this.isLoading = true;
      let pages = await Backend.getUsers(0).then(res => {
        return Math.ceil(res.total / this.limit);
      });
      this.isLoading = false;
      this.usersList = [];
      this.total = 0;
      for (let i = 1; i <= pages; i++) {
        this.isLoading = true;
        let usersList = await Backend.getUsers(this.limit, i, this.sortBy, this.sortByAscending, this.createdAfter, this.createdBefore);
        this.pageChanged = false;
        this.isLoading = false;
        if (usersList.total > 0) {
          this.usersList = this.usersList.concat(usersList.users);
          this.total += usersList.total;
        }
      }
    },


    async getChipsStats() {
      this.isChipsLoading = true;

      this.chipStatsData = await Backend.getChipStats()
      console.log(this.chipStatsData)

      let totalUsers = await Backend.getUsers(0).then(res => {
        return res.total;
      });

      let pages = Math.ceil(totalUsers / 1000);
      let usersList = [];
      for (let i = 1; i <= pages; i++) {
        usersList = usersList.concat(await Backend.getUsers(this.limit, i, "created", this.sortByAscending).then(res => {
          return res.users
        }));
      }


      for (const user of usersList) {
        this.totalChips += user.chips;
        this.totalGems += user.gems;
        this.totalBet += user.totalBet;
        this.totalWon += user.totalWon;
        this.betsBalance += user.totalWon - user.totalBet;
        this.totalClaimedFreeChips += user.totalClaimedFreeChips;


      }
      let totalNotifs = await Backend.getNotifications(null, 0).then(res => {
        return (res.total)
      })
      pages = Math.ceil(totalNotifs / 1000);
      let notifsList = [];

      for (let i = 1; i <= pages; i++) {
        notifsList = notifsList.concat(
            await Backend.getNotifications(null, 1000, i).then(res => {
              return (res.notifications)
            })
        );
      }
      let leaderboardReward = 0;
      let giveawayReward = 0;
      notifsList.forEach(notif => {
        giveawayReward += notif.prizeAmount;
        if (/leaderboard/i.test(notif.title) && /(\d+) chips/.test(notif.message)) {
          leaderboardReward += parseInt(notif.message.match(/(\d+) chips/)[1])
        }
      });
      this.totalLeaderboard += leaderboardReward;
      this.otherFreeChips += giveawayReward;

      console.log("chips stats : \n" +
          "total chips: " + this.totalChips + "\n" +
          "total bets: " + this.totalBet + "\n" +
          "total wins: " + this.totalWon + "\n" +
          "betsBalance: " + this.betsBalance + "\n" +
          "totalClaimedFreeChips: " + this.totalClaimedFreeChips + "\n" +
          "totalLeaderboard: " + this.totalLeaderboard + "\n" +
          "otherFreeChips: " + this.otherFreeChips)

      this.isChipsLoading = false;
    },
    chartData(chartName) {
      let labels = [];
      let datasets = [{
        backgroundColor: '#ff595a',
        label: chartName,
        data: []
      }];
      if (chartName === "totalchips" && this.chipStatsData.length > 0) {
        this.chipStatsData.forEach((value) => {
          // console.log(value)
          labels.push(new Date(parseInt(value[0])).toLocaleDateString("fr"));
          datasets[0].data.push(JSON.parse(value[1]).totalChips)
        })
      }
      if (chartName === "totalGems" && this.chipStatsData.length > 0) {
        this.chipStatsData.forEach((value) => {
          // console.log(value)
          if (JSON.parse(value[1]).totalGems && JSON.parse(value[1]).totalGems > 0) {
            labels.push(new Date(parseInt(value[0])).toLocaleDateString("fr"));
            datasets[0].data.push(JSON.parse(value[1]).totalGems)
          }
        })
      }
      if (chartName === "totalchipsVariation" && this.chipStatsData.length > 0) {
        let previous = 0
        this.chipStatsData.forEach((value) => {
          // console.log(value)
          if (previous > 0) {
            labels.push(new Date(parseInt(value[0])).toLocaleDateString("fr"));
            datasets[0].data.push(JSON.parse(value[1]).totalChips - previous)
          }
          previous = JSON.parse(value[1]).totalChips
        })
      }
      if (chartName === "totalBet" && this.chipStatsData.length > 0) {
        let previous = 0
        this.chipStatsData.forEach((value) => {
          // console.log(value)
          if (previous > 0 && JSON.parse(value[1]).totalBet - previous > 0) {
            labels.push(new Date(parseInt(value[0])).toLocaleDateString("fr"));
            datasets[0].data.push(JSON.parse(value[1]).totalBet - previous)
          }
          previous = JSON.parse(value[1]).totalBet
        })
      }
      if (chartName === "totalWon" && this.chipStatsData.length > 0) {
        let previous = 0
        this.chipStatsData.forEach((value) => {
          // console.log(value)
          if (previous > 0 && JSON.parse(value[1]).totalWon - previous > 0) {
            labels.push(new Date(parseInt(value[0])).toLocaleDateString("fr"));
            datasets[0].data.push(JSON.parse(value[1]).totalWon - previous)
          }
          previous = JSON.parse(value[1]).totalWon
        })
      }
      if (chartName === "totalClaimedFreeChips" && this.chipStatsData.length > 0) {
        let previous = 0
        this.chipStatsData.forEach((value) => {
          // console.log(value)
          if (previous > 0 && JSON.parse(value[1]).totalWon - previous > 0) {
            labels.push(new Date(parseInt(value[0])).toLocaleDateString("fr"));
            datasets[0].data.push(JSON.parse(value[1]).totalClaimedFreeChips - previous)
          }
          previous = JSON.parse(value[1]).totalClaimedFreeChips
        })
      }
      if (chartName === "totalLeaderboard" && this.chipStatsData.length > 0) {
        this.chipStatsData.forEach((value) => {
          // console.log(value)
          labels.push(new Date(parseInt(value[0])).toLocaleDateString("fr"));
          datasets[0].data.push(JSON.parse(value[1]).totalLeaderboard)
        })
      }
      if (chartName === "otherFreeChips" && this.chipStatsData.length > 0) {
        this.chipStatsData.forEach((value) => {
          // console.log(value)
          labels.push(new Date(parseInt(value[0])).toLocaleDateString("fr"));
          datasets[0].data.push(JSON.parse(value[1]).otherFreeChips)
        })
      }
      if (chartName === "DAU" && Object.entries(this.activeUsersTable).length > 0) {
        Object.entries(this.activeUsersTable).forEach((value) => {
          // console.log(value)
          labels.push(new Date(parseInt(value[0])).toLocaleDateString("fr"));
          datasets[0].data.push(value[1].dailyActive)
        })
      }
      if (chartName === "New users" && Object.entries(this.newUsersTable).length > 0) {
        Object.entries(this.newUsersTable).forEach((value) => {
          labels.push(new Date(parseInt(value[0])).toLocaleDateString("fr"));
          datasets[0].data.push(value[1].total)
        })
      }
      if (chartName === "repeat buyers" && Object.entries(this.purchasesList).length > 0) {
        for (let i = 1; i <= this.returningBuyersAmount; i++) {
          labels.push(i);
          datasets[0].data.push(this.getRepeatBuyers(i))
        }
      }
      if (chartName === "number of bets" && Object.entries(this.betsTable).length > 0) {
        Object.entries(this.betsTable).forEach((value) => {
          // console.log(value)
          labels.push(new Date(parseInt(value[0])).toLocaleDateString("fr"));
          datasets[0].data.push(value[1].numBets)
        })
      }
      return {
        labels: labels,
        datasets: datasets,
      }
    },
  }
}
</script>

<style scoped>
.flex-row{
  display: flex;
  flex-direction: row;
}

.flex-column{
  display: flex;
  flex-direction: column;
}

.page {
  width: 100%;
  position: relative;
  margin: 0 30px;
  overflow: auto;
}

form {
  text-align: left;
}


.page {
  position: relative;
  margin: 0 30px;
  display: flex;
  align-content: flex-start;
  gap: 10px;
}

.info-card {
  position: relative;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  height: 200px;
  width: 200px;
  background: #005da7;
  color: white;
  filter: drop-shadow(2px 4px 1px gray);
}

.loading-img {
  width: 80%;
}

img {
  max-width: 50px;
}

.chart-wrap {
  --x: 250px;
  height: var(--x);
  width: calc(var(--x) * 2);
}
</style>