<template>
  <div class="page flex-column" style="align-items: flex-start; padding: 20px; overflow: auto;">
    <h2>
      Coming soon
    </h2>
  </div>
</template>

<script>
import Backend from "@/classes/backend";

export default {
  name: "redeemCodes",
  components: {
  },
  data() {
    return {
      note: "",
      title: "",
      message: "",
      type: "chips",
      scratchGame: "panda",
      monthlyCardType: "bronze",
      getType: "all",
      prizeType: "chips",
      redeemCodesAmount: 1,
      numUsesPerCode: 1,
      chipsAmount: 0,
      redeemsList: [],
      date: null,
      resultRedeemCodes: {},
      openUser: false,
      selectedUserID: '',
    }
  },
  watch: {
    getType() {
      this.getNotifications();
    }
  },
  computed: {
    users() {
      return null
    },
    codesData() {
      let output = [];
      this.redeemsList.forEach(redeem => {
        output.push({
          admin: redeem.admin,
          redeemId: redeem._id,
          code: "",
          numUsesRemaining: "",
          redeemedBy: [],
          createdTimestamp: redeem.createdTimestamp,
          enabled: redeem.enabled,
          expiryTimestamp: redeem.expiryTimestamp,
          notes: redeem.notes,
          numChips: redeem.numChips,
          numCodes: redeem.numCodes,
          numUsesPerCode: redeem.numUsesPerCode,
          type: redeem.type,
        })
        redeem.codes.forEach(redeemCode => {
          output.push({
            admin: "",
            redeemId: "",
            code: redeemCode.code,
            numUsesRemaining: redeemCode.numUsesRemaining,
            redeemedBy: redeemCode.redeemedBy,
            createdTimestamp: redeem.createdTimestamp,
            enabled: "",
            expiryTimestamp: redeem.expiryTimestamp,
            notes: redeem.notes,
            numChips: "",
            numCodes: "",
            numUsesPerCode: "",
            type: "",
          })
        })
      })
      return output
    }
  },
  mounted() {
    this.getRedeemCodes();
  },
  methods: {
    async createCodes() {
      this.resultRedeemCodes = await Backend.createRedeemCodes(this.type, this.redeemCodesAmount, this.chipsAmount, this.scratchGame,
          this.note, (this.date ? new Date(this.date).valueOf() : 0), this.numUsesPerCode, this.monthlyCardType);
      this.getRedeemCodes();
    },

    async getRedeemCodes() {
      this.redeemsList = await Backend.getRedeemCodes(this.getType === "all" ? null : this.getType, 50).then(res => {
        return res.redeems
      });
    },

    humanTime(timestamp) {
      let date = new Date(timestamp);
      return date.toUTCString();
    },
  }
}
</script>

<style scoped>
.input {
  width: 600px;
}

td {
  max-width: unset;
  white-space: break-spaces;
}

.userPage {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  overflow: auto;
  background: white;
  margin: 0;
}

.closeUserPage {
  position: fixed;
  top: 80px;
  left: 0;
}
</style>