import {createStore} from 'vuex'
import Backend from "@/classes/backend";

export const store = createStore({
    state: {
        username: "",
        password: "",
        isLoggedIn: false,
        environment: "prod",
    },
    getters: {
        getEnvironment: state => {
            if (state.environment === "prod" && localStorage.getItem('environment') &&
                localStorage.getItem('environment') !== "prod") {
                state.environment = localStorage.getItem('environment')
            }
            return state.environment;
        },
        getUsername: state => {
            return state.username;
        },
        getPassword: state => {
            return state.password;
        },
        getIsLoggedIn: state => {
            if (!state.isLoggedIn && localStorage.getItem('credentials')) {
                state.username = JSON.parse(localStorage.getItem('credentials')).username
                state.password = JSON.parse(localStorage.getItem('credentials')).password
                state.isLoggedIn = true;
            }
            return state.isLoggedIn;
        },
    },
    mutations: {
        async login(state, credentials) {
            state.username = credentials.username;
            state.password = credentials.password;
        },
        async changePassword(state, newPassword) {
            state.password = newPassword;
        },
        async setEnvironment(state, environment) {
            state.environment = environment;
        },
        logout(state) {
            state.isLoggedIn = false;
        }
    },
    actions: {
        async login({commit, dispatch}, login) {
            commit("login", login)
            let result = await Backend.getState();
            if (!result.error) {
                this.state.isLoggedIn = true;
                localStorage.setItem('credentials', JSON.stringify(login));
            } else {
                commit("logout")
            }
            await dispatch('loadData')
        },
        logout({commit}) {
            commit("login", {username: "", password: ""})
            commit('logout');
            localStorage.removeItem('credentials');
        },
        setEnvironment({commit}, environment) {
            if (environment === "test" || environment === "prod") {
                localStorage.setItem("environment", environment)
                commit("setEnvironment", environment)
            }
        },
        loadData({commit}) {
            commit("setEnvironment", localStorage.getItem("environment"))
        }
    }
})