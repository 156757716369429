<template>
  <menus-warp v-if="loggedIn"/>
  <login-page v-else/>
</template>

<script>
import MenusWarp from "@/components/MenusWrap";
import LoginPage from "@/components/pages/LoginPage";

export default {
  name: 'App',
  components: {
    LoginPage,
    MenusWarp
  },
  computed: {
    loggedIn() {
      return this.$store.getters.getIsLoggedIn
    }
  },
}
</script>

<style>
router-view{
  position: relative;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

th, td {
  max-width: 220px;
  overflow-wrap: break-word;
  border: 1px black solid;
}


.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

</style>
