<template>
  <div class="page flex-column" style="align-items: flex-start; padding: 20px; overflow: auto;">
    <h2>
      Coming soon
    </h2>
  </div>
</template>

<script>
import Backend from "@/classes/backend";
import {scratchCards} from "../../constants/index";

export default {
  name: "sentNotification",
  props: {
    usersList: {
      type: String,
      required: false,
      default: "[]",
    },
  },
  data() {
    return {
      scratchCards,
      usersInput: JSON.parse(this.$props.usersList),
      title: "",
      message: "",
      type: "news",
      getType: "prize",
      prizeType: "chips",
      game: "panda",
      prizeAmount: 1,
      notificationsList: [],
      userId: null,
      monthlyCardType: "bronze",
    }
  },
  watch: {
    getType() {
      this.getNotifications();
    }
  },
  computed: {
    users() {
      if (this.usersInput.length === 0)
        return null
      else
        return this.usersInput.match(/\w{24}/g)
    }
  },
  mounted() {
    this.getNotifications();
  },
  methods: {
    async sendNotification() {
      let res = await Backend.sendNotification(this.title, this.message, this.users, this.type, this.prizeType,
          this.prizeAmount, this.game, this.monthlyCardType);
      window.alert(JSON.stringify(res));
      this.getNotifications();
    },
    async getNotifications() {
      this.notificationsList = await Backend.getNotifications(this.getType, 50, undefined, this.userId).then(res => {
        return res.notifications
      });
    },
    humanTime(timestamp) {
      let date = new Date(timestamp);
      return date.toUTCString();
    },
  }
}
</script>

<style scoped>
.input {
  width: 600px;
}
</style>